import React from 'react'
import { Link } from 'react-router-dom'

function contact() {
    return (
        <>
            <section id="contact" className="contact-area after-none contact-bg pt-60 pb-120 p-relative fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <p>
                                <img src="assets/img/bg/contact-img.png" alt="map" />
                            </p>
                            <div className="slider-btn">
                                <Link to="/contact" className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default contact