import React from 'react'
import { Link } from 'react-router-dom'
import gallery01 from '../../assets/img/gallery/protfolio-details-img-01.png'
import gallery02 from '../../assets/img/gallery/protfolio-img12.png'
import gallery03 from '../../assets/img/gallery/protfolio-img13.png'

function Projectarea() {
    return (
        <>
            <div className="about-area5 about-p p-relative">
                <div className="container pt-120 pb-90">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 order-1">
                            <div className="service-detail">
                                <div className="content-box">
                                    <div className="client-info">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8">
                                                <ul>
                                                    <li>
                                                        <p>Client</p>
                                                        <h3>Rosalina D. William</h3>
                                                    </li>
                                                    <li>
                                                        <p>Project Name</p>
                                                        <h3>7Eleven</h3>
                                                    </li>
                                                    <li>
                                                        <p>Budget</p>
                                                        <h3>$100 Million</h3>
                                                    </li>
                                                    <li>
                                                        <p>Duration</p>
                                                        <h3>6 Months</h3>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-4 col-md-4 text-right">
                                                <Link to="/contact" className="btn ss-btn" data-animation="fadeInUp" data-delay=".4s" >
                                                    <i className="fas fa-angle-right" /> free tour
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="image-column col-xl-12 col-lg-12 col-md-12">
                                                <figure className="image">
                                                    <img src={gallery01} alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <h2> Project Overview</h2>
                                    <p>
                                        Architecture design is the process of planning and creating the
                                        structure, layout, and overall design of a building, landscape, or
                                        other physical space. It involves the integration of form,
                                        function, and aesthetics to create a cohesive and functional
                                        design that meets the needs of the users. The process of
                                        architecture design typically involves several steps, including
                                        research and analysis, conceptualization, schematic design, design
                                        development, and construction documentation. During each phase,
                                        the designer works with the client to refine and improve the
                                        design based on feedback and requirements. The final product of
                                        architecture design is a set of detailed drawings and
                                        specifications that guide the construction of the building or
                                        space. These drawings may include floor plans, elevations,
                                        sections, and details, as well as information about materials,
                                        finishes, and other construction details.
                                    </p>
                                    <p>
                                        Architecture design can be applied to a wide range of projects,
                                        from small residential homes to large commercial buildings, urban
                                        landscapes, and public spaces. The process of architecture design
                                        is critical to creating functional, aesthetically pleasing, and
                                        sustainable spaces that meet the needs of the users and the
                                        environment.{" "}
                                    </p>
                                    <h3>Work Process</h3>
                                    <p>
                                        <strong>Research and Analysis:</strong> The first step in the
                                        design process is to research and analyze the project
                                        requirements, site conditions, and other relevant factors. This
                                        includes gathering information about the project scope, budget,
                                        zoning regulations, environmental factors, and other site-specific
                                        considerations.{" "}
                                    </p>
                                    <p>
                                        <strong>Conceptualization:</strong> The second step involves
                                        generating ideas and conceptual designs for the project. This
                                        includes developing a design brief, creating sketches and
                                        preliminary drawings, and exploring different design options.{" "}
                                    </p>
                                    <p>
                                        <strong>Schematic Design:</strong> The third step involves
                                        refining the design concept and developing a schematic design.
                                        This includes creating more detailed drawings and models,
                                        evaluating the feasibility of the design, and making any necessary
                                        adjustments.{" "}
                                    </p>
                                    <p>
                                        <strong>Design Development:</strong> The fourth step involves
                                        further developing the design, including selecting materials and
                                        finishes, refining the details, and coordinating with consultants
                                        and contractors.
                                    </p>
                                    <p>
                                        <strong>Construction Documentation:</strong> The fifth step
                                        involves creating detailed construction documents, including
                                        drawings and specifications, that outline the requirements for
                                        building the project.
                                    </p>
                                    <div className="row mt-50 mb-50">
                                        <div className="col-lg-6 col-md-6">
                                            <img src={gallery02} alt="img" />
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <img src={gallery03} alt="img" />
                                        </div>
                                    </div>
                                    <div className="services-nav">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <Link to="/services-details">
                                                    <i className="fas fa-long-arrow-left" /> Masun App Design
                                                </Link>
                                            </div>
                                            <div className="col-lg-6 text-right">
                                                <Link to="/services-details">
                                                    Astro Website Design{" "}
                                                    <i className="fas fa-long-arrow-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projectarea