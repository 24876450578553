import React from 'react'
import { Link } from 'react-router-dom'

function Pricingarea() {
    return (
        <>
            <section id="pricing" className="pricing-area pt-120 pb-60 fix p-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head">
                                    <h3> Basic Plan </h3>
                                    <div className="price-count">
                                        <h2>$49</h2> <span>Per user per month</span>
                                    </div>
                                    <p>Perfect for early-stage startups</p>
                                    <div className="pricing-btn">
                                        <Link to="/contact" className="btn ss-btn active">
                                            Get Started Now
                                        </Link>
                                        <Link to="/contact" className="btn ss-btn">
                                            Chat To Selesman
                                        </Link>
                                    </div>
                                </div>
                                <div className="pricing-body mt-20 text-left">
                                    <h4>Features</h4>
                                    <p>
                                        Everything in our free plan it’s include with all premium
                                        packages.
                                    </p>
                                    <ul className="mt-20">
                                        <li>Access to basic features</li>
                                        <li>Basic reporting and analytics</li>
                                        <li>Up to 10 individual users</li>
                                        <li>20Gb Individual data each users</li>
                                        <li>Basic chat &amp; email support</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 active mb-60">
                                <div className="pricing-head">
                                    <div className="month">Popular</div>
                                    <h3> Basic Plan </h3>
                                    <div className="price-count">
                                        <h2>$99</h2> <span>Per user per month</span>
                                    </div>
                                    <p>Perfect for early-stage startups</p>
                                    <div className="pricing-btn">
                                        <Link to="/contact" className="btn ss-btn active">
                                            Get Started Now
                                        </Link>
                                        <Link to="/contact" className="btn ss-btn">
                                            Chat To Selesman
                                        </Link>
                                    </div>
                                </div>
                                <div className="pricing-body mt-20 text-left">
                                    <h4>Features</h4>
                                    <p>
                                        Everything in our free plan it’s include with all premium
                                        packages.
                                    </p>
                                    <ul className="mt-20">
                                        <li>Access to basic features</li>
                                        <li>Basic reporting and analytics</li>
                                        <li>Up to 10 individual users</li>
                                        <li>20Gb Individual data each users</li>
                                        <li>Basic chat &amp; email support</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head">
                                    <h3> Basic Plan </h3>
                                    <div className="price-count">
                                        <h2>$199</h2> <span>Per user per month</span>
                                    </div>
                                    <p>Perfect for early-stage startups</p>
                                    <div className="pricing-btn">
                                        <Link to="/contact" className="btn ss-btn active">
                                            Get Started Now
                                        </Link>
                                        <Link to="/contact" className="btn ss-btn">
                                            Chat To Selesman
                                        </Link>
                                    </div>
                                </div>
                                <div className="pricing-body mt-20 text-left">
                                    <h4>Features</h4>
                                    <p>
                                        Everything in our free plan it’s include with all premium
                                        packages.
                                    </p>
                                    <ul className="mt-20">
                                        <li>Access to basic features</li>
                                        <li>Basic reporting and analytics</li>
                                        <li>Up to 10 individual users</li>
                                        <li>20Gb Individual data each users</li>
                                        <li>Basic chat &amp; email support</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pricingarea