import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import gallery01 from '../../assets/img/gallery/protfolio-img14.png'
import gallery02 from '../../assets/img/gallery/protfolio-img15.png'
import gallery03 from '../../assets/img/gallery/protfolio-img16.png'
import 'swiper/css';
import 'swiper/css/pagination';

const Case = {
    speed: 1000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

function Protofolio() {
    return (
        <>
            <section className="gallery-two pt-120 pb-120 fix" style={{ background: "#fff" }}>
                <div className="container">
                    <div className="portfolio ">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="section-title p-relative mb-50 wow fadeInLeft  animated" data-animation="fadeInLeft" data-delay=".4s" >
                                    <h5>portfolio</h5>
                                    <h2>Recet Case Study</h2>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 text-right"></div>
                        </div>
                        <Swiper className="portfolio-active wow fadeInUp  animated " data-animation="fadeInUp" data-delay=".4s"   {...Case} slidesPerView={3}>
                            <SwiperSlide className="grid-item financial">
                                <Link to="/project-details">
                                    <figure className="gallery-image gallery-image-two">
                                        <img src={gallery01} alt="img" className="img" />
                                        <figcaption>
                                            <span>Consultancy</span>
                                            <h4>Andos Business Consultancy</h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className="grid-item financial banking">
                                <Link to="/project-details">
                                    <figure className="gallery-image gallery-image-two">
                                        <img src={gallery02} alt="img" className="img"
                                        />
                                        <figcaption>
                                            <span>Consultancy</span>
                                            <h4>Andos Business Consultancy</h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className="grid-item insurance">
                                <Link to="/project-details">
                                    <figure className="gallery-image gallery-image-two">
                                        <img src={gallery03} alt="img" className="img" />
                                        <figcaption>
                                            <span>Consultancy</span>
                                            <h4>Andos Business Consultancy</h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className="grid-item financial">
                                <Link to="/project-details">
                                    <figure className="gallery-image gallery-image-two">
                                        <img src={gallery01} alt="img" className="img" />
                                        <figcaption>
                                            <span>Consultancy</span>
                                            <h4>Andos Business Consultancy</h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className="grid-item financial banking">
                                <Link to="/project-details">
                                    <figure className="gallery-image gallery-image-two">
                                        <img src={gallery02} alt="img" className="img" />
                                        <figcaption>
                                            <span>Consultancy</span>
                                            <h4>Andos Business Consultancy</h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className="grid-item insurance">
                                <Link to="/project-details">
                                    <figure className="gallery-image gallery-image-two">
                                        <img src={gallery01} alt="img" className="img" />
                                        <figcaption>
                                            <span>Consultancy</span>
                                            <h4>Andos Business Consultancy</h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-2 col-md-4">
                            <Link to="/contact" className="btn ss-btn" data-animation="fadeInUp" data-delay=".4s">
                                <i className="fas fa-angle-right" /> more works
                            </Link>
                        </div>
                        <div className="col-lg-10 col-md-8">
                            <span>5000+ users work done with good quality.</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Protofolio