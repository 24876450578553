import React from 'react'
import Headerthree from '../Menhomethree/Headerthree'
import Homesliderthree from '../Menhomethree/Homesliderthree'
import Sliderservices from '../Menhomethree/Sliderservices'
import Aboutthree from '../Menhomethree/Aboutthree'
import Protofolio from '../Menhomethree/Protofolio'
import Whyus from '../Menhomethree/Whyus'
import Brand from '../Menhomethree/Brand'
import Teamarea from '../Menhomethree/Teamarea'
import Contact from '../Menhomethree/Contact'
import Blog from '../Menhomethree/Blog'
import Footer from '../Menhomethree/Footer'

function Main() {
  return (
    <>
      <main>
        <Headerthree />
        <Homesliderthree />
        <Sliderservices />
        <Aboutthree />
        <Protofolio />
        <Whyus />
        <Brand />
        <Teamarea />
        <Contact />
        <Blog />
        <Footer />
      </main>
    </>
  )
}

export default Main