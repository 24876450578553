import React from 'react';
import { Link } from 'react-router-dom';
import aboutImage01 from '../../assets/img/features/about_img.png';
import aboutImage2 from '../../assets/img/features/about_img2.png';

const roiWorkspaceData = {
    title: 'roi WORKSPACE',
    subTitle: 'Start your own data analyse in seconds. Let’s go!',
    description:'Your personal in-browser tool to write, run, and share your data analysis. DataCamp for Classrooms is always free for you and your students.',
    buttonText: 'get in touch',
    buttonLink: 'about',
};

const workProcessData = [
    {
        number: '01',
        title: 'Assess',
        description: 'Test your skills and track progress',
    },
    {
        number: '02',
        title: 'Learn',
        description: 'Complete interactive courses',
    },
    {
        number: '03',
        title: 'Practice',
        description: 'Practice with quick daily challenges',
    },
    {
        number: '04',
        title: 'Apply',
        description: 'Solve real-world problems',
    },
];

function About02() {
    return (
        <>
            <section id="about" className="about-area2 pt-120 pb-120 p-relative fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="s-about-img p-relative wow fadeInLeft animated text-left"  data-animation="fadeInLeft"  data-delay=".4s" >
                                <img src={aboutImage01} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="about-content2 wow fadeInRight animated pt-50 pl-40" data-animation="fadeInRight"  data-delay=".4s" >
                                <div className="about-title2 second-title pb-50 mb-25">
                                    <h5>{roiWorkspaceData.title}</h5>
                                    <h2>{roiWorkspaceData.subTitle}</h2>
                                </div>
                                <p>{roiWorkspaceData.description}</p>
                                <Link to={roiWorkspaceData.buttonLink} className="btn btn2 mt-30">
                                    <i className="fas fa-angle-right" /> {roiWorkspaceData.buttonText}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="about-content2 wow fadeInRight animated pt-50 pr-30"  data-animation="fadeInRight"  data-delay=".4s" >
                                <div className="about-title2 about-title2-bdrnone second-title mb-25">
                                    <h5>work process</h5>
                                    <h2>Join more than 11 million learners worldwide</h2>
                                </div>
                                <div className="about-work">
                                    <ul>
                                        {workProcessData.map((item) => (
                                            <li key={item.number}>
                                                <h3>
                                                    <span>{item.number}.</span> {item.title}
                                                </h3>
                                                <p>{item.description}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="s-about-img2 p-relative wow fadeInLeft animated text-right" data-animation="fadeInLeft" data-delay=".4s" >
                                <img src={aboutImage2} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About02;
