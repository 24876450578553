import React from 'react'
import { Link } from 'react-router-dom'
import team01 from '../../assets/img/team/team-single.png'
import icon01 from '../../assets/img/icon/team-single-icon-01.png'
import icon02 from '../../assets/img/icon/team-single-icon-02.png'
import icon03 from '../../assets/img/icon/team-single-icon-03.png'
import img01 from '../../assets/img/icon/cert-img-01.png'
import img02 from '../../assets/img/icon/cert-img-02.png'
import img03 from '../../assets/img/icon/cert-img-03.png'
import img04 from '../../assets/img/icon/cert-img-04.png'

function Teamarea() {
    return (
        <>
            <section className="team-single-area fix p-relative pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="single-team-singel mb-40 p-relative">
                                <div className="team-thumb">
                                    <img src={team01} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="single-team-content-single">
                                <div className="section-title p-relative mb-50 wow fadeInLeft  animated" data-animation="fadeInLeft" data-delay=".4s" >
                                    <h5>founder</h5>
                                    <h2>Miranda H. Halim</h2>
                                    <p>
                                        Architecture design can be applied to a wide range of projects,
                                        from small residential homes to large commercial buildings.
                                    </p>
                                </div>
                                <div className="team-single-info">
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <img src={icon01} alt="img" />
                                            </div>
                                            <div className="text">
                                                <p>Business Email</p>
                                                <h3>
                                                    <Link to="mailto:hi@rosalina.com">hi@rosalina.com</Link>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img src={icon02} alt="img" />
                                            </div>
                                            <div className="text">
                                                <p>Phone Number</p>
                                                <h3>
                                                    <Link to="tel:+777 888 666 00">+777 888 666 00</Link>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img src={icon03} alt="img" />
                                            </div>
                                            <div className="text">
                                                <p>Office Address</p>
                                                <h3>1/A, Booston, NYC</h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-50">
                        <div className="col-lg-3 col-md-3">
                            <img src={img01} alt="img" />
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <img src={img02} alt="img" />
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <img src={img03} alt="img" />
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <img src={img04} alt="img" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Teamarea