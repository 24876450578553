import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Innerblogdtls from '../Menblogdetails/Innerblogdtls'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"With our vastly improved system."} sub={"Home"} title={"Blog Deatlis"}/>
    <Innerblogdtls/>
    <Footer/>
    </>
  )
}

export default Main