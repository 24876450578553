import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Shoparea from '../Menshop/Shoparea'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"Shop"} sub={"Home"} title={"Shop"} />
    <Shoparea />
    <Footer/>
    </>
  )
}

export default Main