import React from 'react';
import { Link } from 'react-router-dom';

const coursesData = [
    {
        id: 1,
        title: 'AI 2.0 Machine Learning',
        category: 'AI, Coding',
        price: '$200.00',
    },
    {
        id: 2,
        title: 'ChatGPT Develop',
        category: 'PHP',
        price: '$200.00',
    },
    {
        id: 3,
        title: 'Basic Automation',
        category: 'WP',
        price: '$200.00',
    },
    {
        id: 4,
        title: 'Advanced Robotics',
        category: 'Product',
        price: '$200.00',
    },
    {
        id: 5,
        title: 'UI & UX Design',
        category: 'Design',
        price: '$200.00',
    },
];

function Course() {
    return (
        <>
            <section className="courses-ai pt-120 pb-120 p-relative" style={{ backgroundColor: '#000000' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title2 center-align mb-80 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <h5>our courses</h5>
                                <h2>Popular paths and courses.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="courses wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 th">course list</div>
                            <div className="col-lg-2 col-md-2 th">category</div>
                            <div className="col-lg-2 col-md-2 th">price</div>
                            <div className="col-lg-4 col-md-4 th text-right">enroll</div>
                        </div>
                        {coursesData.map((course) => (
                            <div className="row" key={course.id}>
                                <div className="col-lg-4 col-md-4 td">
                                    <strong>
                                        <Link to="#">
                                            <i className="fas fa-long-arrow-right" /> {course.title}
                                        </Link>
                                    </strong>
                                </div>
                                <div className="col-lg-2 col-md-2 td">{course.category}</div>
                                <div className="col-lg-2 col-md-2 td">{course.price}</div>
                                <div className="col-lg-4 col-md-4 td text-right">
                                    <Link to="#">Buy Now</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <Link to="/contact" className="btn ss-btn mt-50" data-animation="fadeInUp" data-delay=".4s" >
                                <i className="fas fa-angle-right" /> more course
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Course;
