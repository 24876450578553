import React from 'react';
import { Link } from 'react-router-dom';
import bloginnerB1 from '../../assets/img/blog/inner_b1.jpg';
import bloginnerB2 from '../../assets/img/blog/inner_b2.jpg';
import bloginnerB3 from '../../assets/img/blog/inner_b3.jpg';

const blogData = [
  {
    image: bloginnerB1,
    date: '24th March 2022',
    title: '5 reasons why every tech company should be using Webflow...',
    readMoreLink: '/blog-details',
    views: 100,
    comments: 35,
  },
  {
    image: bloginnerB3,
    date: '24th March 2022',
    title: 'Copywriting for success: why your website needs to sound as good as it looks...',
    readMoreLink: '/blog-details',
    views: 100,
    comments: 35,
  },
  {
    image: bloginnerB2,
    date: '24th March 2022',
    title: 'Nostalgia in Website Copywriting: Examples of Successful Campaigns...',
    readMoreLink: '/blog-details',
    views: 100,
    comments: 35,
  },
];

function Blog() {
  return (
    <>
      <section id="blog" className="blog-area p-relative fix pt-120 pb-90" style={{ background: "#FCF6F0" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title center-align mb-80 text-center wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s" >
                <h5>insights</h5>
                <h2>Company news &amp; insights</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {blogData.map((blog, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="single-post2 hover-zoomin mb-30 wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s" >
                  <div className="blog-thumb2">
                    <Link to={blog.readMoreLink}>
                      <img src={blog.image} alt={`blog-image-${index + 1}`} />
                    </Link>
                  </div>
                  <div className="blog-content2">
                    <div className="date-home">{blog.date}</div>
                    <h4>
                      <Link to={blog.readMoreLink}>
                        {blog.title}
                      </Link>
                    </h4>
                    <div className="row bdr">
                      <div className="col-lg-4 col-md-5">
                        <Link to={blog.readMoreLink}>Read More</Link>
                      </div>
                      <div className="col-lg-8 col-md-7 text-right">
                        <div className="b-meta">
                          <ul>
                            <li>
                              <i className="fas fa-eye" /> {blog.views}{" "}
                            </li>
                            <li>
                              <i className="far fa-comments" /> {blog.comments}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
