import React from 'react'

function contactarea() {
    return (
        <>
            <section className="contact-area contact-bg2 contact-bg3 p-relative fix pt-120 pb-120" data-aos="fade-up" data-aos-duration={1000} >
                <div className="row">
                    <div className="col-lg-12 p-relative">
                        <div className="section-title center-align mb-80 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                            <h5>contact Us</h5>
                            <h2>
                                to Get An Amazing software solution For <br />
                                Your Business
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="container contact-bg02">
    <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12">
            <div class="contact-bg">
                <form action="mail.php" method="post" class="contact-form mt-30">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="contact-field p-relative c-name mb-15">
                                <label>Your name</label>
                                <input type="text" id="firstn" name="firstn" placeholder="First Name" required="" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="contact-field p-relative c-subject mb-15">
                                <label>Your Email</label>
                                <input type="text" id="email" name="email" placeholder="Email" required="" />
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="contact-field p-relative c-subject mb-15">
                                <label>Select operation you want</label>
                                <select name="services" id="sr">
                                    <option value="select-subject">Select Subject</option>
                                    <option value="Website Development">Website Development</option>
                                    <option value="Development of Detailed ERP Solutions">Development of Detailed ERP Solutions</option>
                                    <option value="Email Migration">Email Migration</option>
                                    <option value="Maintenance">Maintenance</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="contact-field p-relative c-message mb-30">
                                <label>Message</label>
                                <textarea name="message" id="message" cols="30" rows="10" placeholder="Write comments"></textarea>
                            </div>
                            <div class="slider-btn">
                                <button class="btn ss-btn active" data-animation="fadeInRight" data-delay=".8s">
                                    <i class="fas fa-angle-right"></i> Submit Now
                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                       {/* <div className="col-lg-6 col-md-12">
                            <iframe
                                src="https://maps.app.goo.gl/gYESaCVTvhxfPixC6"
                                width={600}
                                height={500}
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            />
    </div>*/}
                    </div>
                </div>
            </section>
        </>
    )
}

export default contactarea