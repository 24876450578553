import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Logo1 from '../../assets/img/brand/b-logo1.png';
import Logo2 from '../../assets/img/brand/b-logo2.png';
import Logo3 from '../../assets/img/brand/b-logo3.png';
import Logo4 from '../../assets/img/brand/b-logo4.png';
import Logo5 from '../../assets/img/brand/b-logo5.png';
import BG1 from '../../assets/img/bg/video-bg.png'

const logos = [Logo1, Logo2, Logo3, Logo4, Logo5, Logo3];

const brand = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 3,
                infinite: true,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function Hometwovideo() {

    const [video, setVideo] = useState(false);

    return (
        <>
            <section id="video" className="video-area pt-150 pb-60 p-relative" style={{ backgroundImage: `url(${BG1})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center", }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="s-video-wrap">
                                <div className="s-video-content wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" onClick={() => setVideo(true)}>
                                    <Link to="#" className="popup-video"> play </Link>
                                </div>
                                <div className="section-title2 center-align text-center mt-70 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                    <h5>service overview</h5>
                                    <h2>We work on contingency: you don’t owe anything until we win!</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="brand-area pt-60 pb-60 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    <div className="container">
                        <div className="row brand-active mt-60 pt-60 ">
                            <Slider className="" {...brand}>
                                {logos.map((logo, index) => (
                                    <div key={index} className="col-xl-2">
                                        <div className="single-brand">
                                            <img src={logo} alt={`logo-${index + 1}`} />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            {video &&
                <>
                    <div className="mfp-bg mfp-ready"></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex={-1} style={{ overflow: "hidden auto" }} >
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler" onClick={() => setVideo(false)}>
                                    <button title="Close (Esc)" type="button" className="mfp-close" >
                                        ×
                                    </button>
                                    <iframe
                                        className="mfp-iframe"
                                        src="https://www.youtube.com/embed/6mpDNxI5gFY?autoplay=1"
                                        frameBorder={0}
                                        allowFullScreen=""
                                    />
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default Hometwovideo;
