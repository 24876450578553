import React from 'react'
import { Link } from 'react-router-dom'
import bg from '../../assets/img/bg/skill-img.png'

function Skills() {
    return (
        <>
            <section id="skill" className="skill-area pb-90 p-relative brl">
                <div className="container">
                    <div className="row justify-content-center align-items-end">
                        <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                            <div className="skills-img">
                                <img src={bg} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="section-title center-align mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <h5>skillset</h5>
                                <h2>Data insights to accelerate digital transformations</h2>
                            </div>
                            <div className="skills-content s-about-content">
                                <div className="skills-content s-about-content mt-60">
                                    <div className="skills">
                                        <div className="skill mb-20">
                                            <div className="skill-bar mb-20">
                                                <div className="skill-per" id={94} style={{ width: '94%' }}>
                                                </div>
                                            </div>
                                            <div className="skill-name">
                                                <strong>Brand Identity</strong>
                                            </div>
                                        </div>
                                        <div className="skill mb-20">
                                            <div className="skill-bar mb-20">
                                                <div className="skill-per" id={84} style={{ width: '84%' }} />
                                            </div>
                                            <div className="skill-name">
                                                <strong>Design Experience</strong>
                                            </div>
                                        </div>
                                        <div className="skill mb-20">
                                            <div className="skill-bar mb-20">
                                                <div className="skill-per" id={78} style={{ width: '78%' }} />
                                            </div>
                                            <div className="skill-name">
                                                <strong>Online Advertising</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to="/contact" className="btn ss-btn mt-30" data-animation="fadeInUp" data-delay=".4s" >
                                <i className="fas fa-angle-right" /> get a quote
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Skills