import React from 'react'
import Header from '../Header/Main'
import Slider from '../Menhome/Slider'
import Aboutarea from '../Menhome/Aboutarea'
import Homeservices from '../Menhome/Homeservices'
import Portfolio from '../Menhome/Portfolio'
import Contact from '../Menhome/contact'
import Intregrations from '../Menhome/Intregrations'
import Blog from '../Menhome/Blog'
import Client from '../Menhome/Client'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
      <main>
        <Header />
        <Slider />
        <Aboutarea />
        <Homeservices />
        <Contact />
        <Intregrations />
      </main>
      <Footer/>
    </>
  )
}

export default Main