import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicedtls from '../MenServices/Servicedetails'
import Skills from '../MenServices/Skills'
import Contact from '../MenServices/Contact'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
      <div>
        <Header />
        <Breadcrumb mainheading={"What We Do"} sub={"Home"} title={"Services"} />
        <Servicedtls />
        <Skills />
        <Contact />
        <Footer />
      </div>
    </>
  )
}

export default Main