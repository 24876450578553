import React from 'react'
import { Link } from 'react-router-dom'
import img01 from '../../assets/img/slider/slider-img.png'
import img02 from '../../assets/img/slider/slider-ani-01.png'
import BG from '../../assets/img/slider/slider_bg_01.png'

function Slider() {
    return (
        <>
            <section id="home" className="slider-area fix p-relative">
                <div className="slider-active" style={{ background: "#fff" }}>
                    <div className="single-slider slider-bg d-flex align-items-center" style={{ backgroundImage: `url(${BG})` , backgroundSize: "cover", marginBottom: 56}} > 
                            <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-7 col-md-7">
                                    <div className="slider-content s-slider-content mt-100">
                                        <h2 data-animation="fadeInUp" data-delay=".4s">
                                        Our integrity is unmatched
                                        </h2>
                                        <p data-animation="fadeInUp" data-delay=".6s">
                                        Our staff are our biggest asset, and they are trained to meet both local and international standards.
                                        </p>
                                        <div className="slider-btn mt-50">
                                            <Link to="/About" className="btn ss-btn active" data-animation="fadeInUp" data-delay=".4s" >
                                                <i className="fas fa-angle-right" /> About Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className="banner-img">
                                        <img src={img01} alt="banner" />
                                    </div>
                                    <div className="s-aliment-1 ">
                                        <div className="aliment-icon-red">
                                            <img src={img02} alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Slider