import React from 'react'
import Slider from 'react-slick';
import Testimonial01 from '../../assets/img/testimonial/testi_avatar.png';
import Testimonial02 from '../../assets/img/testimonial/testi_avatar_02.png';
import Testimonial03 from '../../assets/img/testimonial/testi_avatar_03.png';
import Testimonial04 from '../../assets/img/testimonial/testi_avatar_02.png';

const Testimonialnew = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

function Testimonial() {
    return (
        <>
            <section className="testimonial-area pt-120 pb-90 p-relative fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title2 mb-80 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <h5>user reviews</h5>
                                <h2>Don't just take our word for it.</h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <Slider className="testimonial-active wow fadeInUp animated" {...Testimonialnew} data-animation="fadeInUp" data-delay=".4s" >
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <img src={Testimonial01} alt="img" />
                                    </div>
                                    <p>
                                        " We think of it as everyone's responsibility in the organization
                                        to be more data-driven. After all, every single one of us is
                                        probably touching data in some way, regardless of your role."
                                    </p>
                                    <div className="ta-info">
                                        <h6>
                                            alonso a. alson <span>/ head of idea</span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <img src={Testimonial02} alt="img" />
                                    </div>
                                    <p>
                                        " On DataCamp, you learn from the experts. As you are taking
                                        courses, you are really learning from the best instructors in the
                                        world. "
                                    </p>
                                    <div className="ta-info">
                                        <h6>
                                            rosalina d. william <span>/ ceo</span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <img src={Testimonial03} alt="img" />
                                    </div>
                                    <p>
                                        " I've used other sites—Coursera, Udacity, things like that—but
                                        DataCamp's been the one that I've stuck with. "
                                    </p>
                                    <div className="ta-info">
                                        <h6>
                                            miranda h. halim <span>/ head of idea</span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <img src={Testimonial04} alt="img" />
                                    </div>
                                    <p>
                                        " On DataCamp, you learn from the experts. As you are taking
                                        courses, you are really learning from the best instructors in the
                                        world. "
                                    </p>
                                    <div className="ta-info">
                                        <h6>
                                            rosalina d. william <span>/ ceo</span>
                                        </h6>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial