import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <footer className="footer-bg footer-p">
                <div className="footer-top  pt-120 pb-90" style={{ backgroundColor: "#000" }}>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-info">
                                        <h2>Let's discuss your projects now</h2>
                                        <p>Contact us to build modern and innovative software solutions for your businesses. </p>
                                        <div className="footer-social">
                                           
                                            
                                            <Link to="https://www.instagram.com/midklick?igsh=ZGo2NHc4eWdmYmxx">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                            <Link to="https://www.linkedin.com/company/midklick/">
                                                <i className="fab fa-linkedin" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <form action="mail.php" method="post" className="contact-form fcontact" >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="contact-field p-relative c-name mb-20">
                                                    <input type="text" id="firstn" name="firstn" placeholder="Your name **" required="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="contact-field p-relative c-subject mb-20">
                                                    <input type="text" id="email" name="email" placeholder="Business email **" required="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="contact-field p-relative c-message mb-30">
                                                    <textarea name="message" id="message" cols={30} rows={10} placeholder="Message **" defaultValue={""} />
                                                </div>
                                                <div className="slider-btn">
                                                    <button className="btn ss-btn active" data-animation="fadeInRight" data-delay=".8s" >
                                                        <span>
                                                            <i className="fas fa-angle-right" /> Submit Now
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 text-center">
                                Copyright &amp; design By <Link to="https://www.instagram.com/midklick?igsh=ZGo2NHc4eWdmYmxx">@MidKLICK</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer