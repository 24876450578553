import React from 'react'
import NumberCounter from 'number-counter';
import BG2 from '../../assets/img/bg/data-science-cta-icon.png'
import { Link } from 'react-router-dom';

function Data() {
    return (
        <>
            <section className="data-science-cta p-relative fix" data-aos="fade-up" data-aos-duration={1000} >
                <div className="container" style={{ backgroundImage: `url(${BG2})`,  backgroundColor: "#2969E6",  backgroundRepeat: "no-repeat", backgroundSize: "cover"  }} >
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-8 col-lg-8 col-md-12">
                            <div className="data-cta-count">
                                <ul>
                                    <li>
                                        <div className="counter">
                                            <h3 className="count"> 
                                            <NumberCounter start={0} end={+200} duration={3} /></h3>
                                            <span>mil</span>
                                        </div>
                                        <p>Human labor hours saved with help of AI</p>
                                    </li>
                                    <li>
                                        <div className="counter">
                                            <h3 className="count">
                                            <NumberCounter start={0} end={10} duration={3} />
                                            </h3>
                                            <span>b+</span>
                                        </div>
                                        <p>Generated revenue by AI Solutions</p>
                                    </li>
                                    <li>
                                        <div className="counter">
                                            <span>$</span>
                                            <h3 className="count">
                                            <NumberCounter start={0} end={10} duration={3}/>
                                            </h3>
                                            <span>mil+</span>
                                        </div>
                                        <p>Saved operational costs due to AI</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="email">
                                <div className="icon">
                                    <i className="far fa-envelope-open" />
                                </div>
                                <div className="text">
                                    <Link to="mailto:info@gentech.com">info@gentech.com</Link>
                                    <p>Business Email</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Data