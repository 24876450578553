import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import test01 from '../../assets/img/testimonial/testi_avatar.png'
import test02 from '../../assets/img/testimonial/testi_avatar_02.png'
import test03 from '../../assets/img/testimonial/testi_avatar_03.png'
import test04 from '../../assets/img/testimonial/testi_avatar_02.png'

const Test = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

function Skillsdtls() {
    return (
        <>
            <section id="skill" className="skill-area skill-area2 skill-area3 fix p-relative pt-120 pb-120" style={{ background: "#DCE864" }} >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="section-title center-align mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <h5>skillset</h5>
                                <h2>Data insights to accelerate digital transformations</h2>
                            </div>
                            <div className="skills-content s-about-content">
                                <div className="skills-content s-about-content mt-60" >
                                    <div className="skills">
                                        <div className="skill mb-20">
                                            <div className="skill-bar mb-20">
                                                <div className="skill-per" id={94} style={{ width: '94%' }} />
                                            </div>
                                            <div className="skill-name">
                                                <strong>Brand Identity</strong>
                                            </div>
                                        </div>
                                        <div className="skill mb-20">
                                            <div className="skill-bar mb-20">
                                                <div className="skill-per" id={84} style={{ width: '84%' }} />
                                            </div>
                                            <div className="skill-name">
                                                <strong>Design Experience</strong>
                                            </div>
                                        </div>
                                        <div className="skill mb-20">
                                            <div className="skill-bar mb-20">
                                                <div className="skill-per" id={78} style={{ width: '78%' }} />
                                            </div>
                                            <div className="skill-name">
                                                <strong>Online Advertising</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to="/contact" className="btn ss-btn mt-30" data-animation="fadeInUp" data-delay=".4s" >
                                <i className="fas fa-angle-right" /> get a quote
                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                            <Slider className="testimonial-active3 wow fadeInUp animated" {...Test} data-animation="fadeInUp" data-delay=".4s" >
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <img src={test01} alt="img" />
                                    </div>
                                    <p>
                                        " We think of it as everyone's responsibility in the organization
                                        to be more data-driven. After all, every single one of us is
                                        probably touching data in some way, regardless of your role."
                                    </p>
                                    <div className="ta-info">
                                        <h6>
                                            alonso a. alson <span>/ head of idea</span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <img src={test02} alt="img" />
                                    </div>
                                    <p>
                                        " On DataCamp, you learn from the experts. As you are taking
                                        courses, you are really learning from the best instructors in the
                                        world. "
                                    </p>
                                    <div className="ta-info">
                                        <h6>
                                            rosalina d. william <span>/ ceo</span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <img src={test03} alt="img" />
                                    </div>
                                    <p>
                                        " I've used other sites—Coursera, Udacity, things like that—but
                                        DataCamp's been the one that I've stuck with. "
                                    </p>
                                    <div className="ta-info">
                                        <h6>
                                            miranda h. halim <span>/ head of idea</span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <img src={test04} alt="img" />
                                    </div>
                                    <p>
                                        " On DataCamp, you learn from the experts. As you are taking
                                        courses, you are really learning from the best instructors in the
                                        world. "
                                    </p>
                                    <div className="ta-info">
                                        <h6>
                                            rosalina d. william <span>/ ceo</span>
                                        </h6>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Skillsdtls