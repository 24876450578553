import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import blog from '../../assets/img/blog/inner_b1.jpg'
import blog01 from '../../assets/img/blog/blog-user01.png'
import blog02 from '../../assets/img/blog/inner_b3.jpg'
import blog03 from '../../assets/img/blog/blog-user02.png'


const PrevArrow = (props) => {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick}><i className="fas fa-long-arrow-left" /></div>;
};

const NextArrow = (props) => {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick}><i className="fas fa-long-arrow-right" /></div>;
};

const blogs = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function Blog() {

    return (
        <>
            <section id="blog" className="blog-area3 p-relative fix pb-120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title center-align mb-80  wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                <h5>insights</h5>
                                <h2>Company Insights</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row home-blog-active2">
                        <Slider className='' {...blogs}>
                            <div className="col-lg-6 col-md-6">
                                <div className="single-post3 hover-zoomin mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >

                                    <div className="blog-thumb3">
                                        <Link to="/blog-details">
                                            <img src={blog} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="blog-content3">
                                        <div className="date-home">
                                            <ul>
                                                <li>consultancy</li>
                                                <li>july 21, 2023</li>
                                            </ul>
                                        </div>
                                        <h4>
                                            <Link to="/blog-details">
                                                The scope of business in consultancy can...
                                            </Link>
                                        </h4>
                                        <div className="admin">
                                            <div className="img">
                                                <img src={blog01} alt="img" />
                                            </div>
                                            <div className="text">
                                                <span>Admin</span>
                                                <strong>Miranda H. Halim</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="single-post3 hover-zoomin mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                    <div className="blog-thumb3">
                                        <Link to="/blog-details">
                                            <img src={blog02} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="blog-content3">
                                        <div className="date-home">
                                            <ul>
                                                <li>consultancy</li>
                                                <li>july 21, 2023</li>
                                            </ul>
                                        </div>
                                        <h4>
                                            <Link to="/blog-details">Some of the key service offered...</Link>
                                        </h4>
                                        <div className="admin">
                                            <div className="img">
                                                <img src={blog03} alt="img" />
                                            </div>
                                            <div className="text">
                                                <span>Admin</span>
                                                <strong>Miranda H. Halim</strong>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="single-post3 hover-zoomin mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                    <div className="blog-thumb3">
                                        <Link to="/blog-details">
                                            <img src={blog} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="blog-content3">
                                        <div className="date-home">
                                            <ul>
                                                <li>consultancy</li>
                                                <li>july 21, 2023</li>
                                            </ul>
                                        </div>
                                        <h4>
                                            <Link to="/blog-details">
                                                The scope of business in consultancy can...
                                            </Link>
                                        </h4>
                                        <div className="admin">
                                            <div className="img">
                                                <img src={blog01} alt="img" />
                                            </div>
                                            <div className="text">
                                                <span>Admin</span>
                                                <strong>Miranda H. Halim</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="single-post3 hover-zoomin mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                    <div className="blog-thumb3">
                                        <Link to="/blog-details">
                                            <img src={blog02} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="blog-content3">
                                        <div className="date-home">
                                            <ul>
                                                <li>consultancy</li>
                                                <li>july 21, 2023</li>
                                            </ul>
                                        </div>
                                        <h4>
                                            <Link to="/blog-details">Some of the key service offered...</Link>
                                        </h4>
                                        <div className="admin">
                                            <div className="img">
                                                <img src={blog03} alt="img" />
                                            </div>
                                            <div className="text">
                                                <span>Admin</span>
                                                <strong>Miranda H. Halim</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog