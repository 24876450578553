import React from 'react';
import { Link } from 'react-router-dom';
import icon01 from '../../assets/img/icon/fe-icon01.png';
import icon02 from '../../assets/img/icon/fe-icon02.png';
import icon03 from '../../assets/img/icon/fe-icon03.png';
import icon04 from '../../assets/img/icon/fe-icon04.png';

const servicesData = [
    {
        icon: icon01,
        title: 'Website Development',
        description: 'Initially, Midklick was founded to carry out a website development solution.',
        link: '/services-details',
    },
    {
        icon: icon02,
        title: 'ERP Solutions',
        description: 'Building ERP solutions is what Midklick focuses on the most. And we take pride in our work.',
        link: '/services-details',
    },
    {
        icon: icon03,
        title: 'Email Migration',
        description: 'The process in which an email or multiple email messages are migrated from one server to another.',
        link: '/services-details',
    },
    {
        icon: icon04,
        title: 'Maintenance',
        description: 'ERP solution & website maintenance',
        link: '/services-details',
    },
];

function Homeservices() {
    return (
        <>
            <section id="service-details2" className="service-details2 pt-120 pb-120 p-relative" style={{ backgroundColor: '#000' }} >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title center-align mb-80 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                <h5>services</h5>
                                <h2>MidKlick operations:</h2>
                            </div>
                        </div>
                         {servicesData.map((service, index) => (
                            <div key={index} className="col-lg-3 col-md-6">
                                <div className="services-02-item mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                    <div className="services-02-thumb">
                                        <img src={service.icon} alt="img" />
                                    </div>
                                    <div className="services-02-content">
                                        <h3><Link to={service.link}>{service.title}</Link></h3>
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
     );
}

export default Homeservices;
