import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Innerblog from '../Menblog/Innerblog'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
      <Header />
      <Breadcrumb mainheading={"News & Insights"} sub={"Home"} title={"Blog"} />
      <Innerblog />
      <Footer />
    </>
  )
}

export default Main