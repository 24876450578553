import React from 'react';
import { Link } from 'react-router-dom';

const services = [
  {
    icon: 'assets/img/icon/sr-icon01.png',
    title: 'Backup & Recovery',
    description: 'Edit your work and preview the changes on the screen live with page builder.',
  },
  {
    icon: 'assets/img/icon/sr-icon02.png',
    title: 'AI & Automation',
    description: 'Edit your work and preview the changes on the screen live with page builder.',
  },
  {
    icon: 'assets/img/icon/sr-icon03.png',
    title: 'Product Design',
    description: 'Edit your work and preview the changes on the screen live with page builder.',
  },
  {
    icon: 'assets/img/icon/sr-icon04.png',
    title: 'VoIP Solutions',
    description: 'Edit your work and preview the changes on the screen live with page builder.',
  },
  {
    icon: 'assets/img/icon/sr-icon05.png',
    title: 'Backup & Recovery',
    description: 'Edit your work and preview the changes on the screen live with page builder.',
  },
  {
    icon: 'assets/img/icon/sr-icon06.png',
    title: 'AI & Automation',
    description: 'Edit your work and preview the changes on the screen live with page builder.',
  },
  {
    icon: 'assets/img/icon/sr-icon07.png',
    title: 'Product Design',
    description: 'Edit your work and preview the changes on the screen live with page builder.',
  },
  {
    icon: 'assets/img/icon/sr-icon08.png',
    title: 'VoIP Solutions',
    description: 'Edit your work and preview the changes on the screen live with page builder.',
  },
];

function Servicedtls() {
  return (
    <>
      <section className="service-details3 pt-120 pb-50 p-relative">
        <div className="container">
          <div className="row align-items-center">
            {services.map((service, index) => (
              <div key={index} className="col-lg-3 col-md-6" >
                <div className="services-02-item mb-70 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                  <div className="services-02-thumb">
                    <img src={service.icon} alt="img" />
                  </div>
                  <div className="services-02-content">
                    <h3><Link to="/services-details">{service.title}</Link></h3>
                    <p>{service.description}</p>
                    <Link to="/services-details">
                      <i className="fas fa-angle-right" /> Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Servicedtls;
