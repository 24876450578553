import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logo/logo.png'

function Footer() {
    return (
        <>
            <footer className="footer-bg footer-bg2 footer-p">
                <div className="footer-top  pt-90 pb-40" style={{ backgroundColor: "#FCF6F0" }} >
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-3 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Courses</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/">Learn Python</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Learn R</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Learn AI</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Learn SQL</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Learn Power BI</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Learn Tableau</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Assessments</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Career Tracks</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Skill Tracks</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Data Science</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Data Course</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/">Upcoming Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Python Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/">R Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/">SQL Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Power BI Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Tableau Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Spreadsheet Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Data Analysis Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Data Visualization Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Machine Learning Courses</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Workspace</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/">Get Started</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Templates</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Integrations</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Documentation</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Rewards</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/">Certifications</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Data Scientist</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Data Analyst</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Hire Data Professionals</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Plans</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/">Pricing</Link>
                                            </li>
                                            <li>
                                                <Link to="/">For Business</Link>
                                            </li>
                                            <li>
                                                <Link to="/">For Universities</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Discounts, Promos &amp; Sales</Link>
                                            </li>
                                            <li>
                                                <Link to="/">DataCamp Donates</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Support</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/">Help Center</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Become an Instructor</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Become an Affiliate</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap" style={{ Color: "#FCF6F0" }}>
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-2">
                                <div className="flogo">
                                    <img src={logo} alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                Make progress on the go with our mobile courses and daily 5-minute
                                coding challenges.
                            </div>
                            <div className="col-lg-6 text-right">
                                <div className="footer-social">
                                    <Link to="#">
                                        <i className="fab fa-facebook-f" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-twitter" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-instagram" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer