import React from 'react'

function Scrollbox() {
    return (
        <>
            <div className="pt-80">
                <div className="scrollbox scrollbox--primary">
                    <div className="scrollbox__item">
                        We combine design, development, &amp; data insights
                    </div>
                    <div className="scrollbox__item">
                        We combine design, development, &amp; data insights
                    </div>
                </div>
                <div className="scrollbox scrollbox--secondary scrollbox--reverse">
                    <div className="scrollbox__item">
                        For data centralized company &amp; all kind of startup
                    </div>
                    <div className="scrollbox__item">
                        For data centralized company &amp; all kind of startup
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scrollbox