import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const timelineItems = [
    {
        year: '2000',
        text1: 'Journey Was Started',
        text2: 'Suggestion and very flexible when it comes to digital',
    },
    {
        year: '2005',
        text1: 'Journey Was Started',
        text2: 'Suggestion and very flexible when it comes to digital',
    },
    {
        year: '2010',
        text1: 'Journey Was Started',
        text2: 'Suggestion and very flexible when it comes to digital',
    },
    {
        year: '2015',
        text1: 'Journey Was Started',
        text2: 'Suggestion and very flexible when it comes to digital',
    },
    {
        year: '2020',
        text1: 'Journey Was Started',
        text2: 'Suggestion and very flexible when it comes to digital',
    },
    {
        year: '2023',
        text1: 'Journey Was Started',
        text2: 'Suggestion and very flexible when it comes to digital',
    },
];

const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev"><i class="far fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="far fa-chevron-right"></i></button>',
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function History() {
    return (
        <>
            <section className="timeline-area pb-90 p-relative fix" style={{ background: "#FCF6F0" }}>
                <div className="container">
                    <div className="row  mb-80">
                        <div className="col-lg-6 col-md-6">
                            <div className="section-title center-align wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                <h5>history</h5>
                                <h2>Company Journey’s</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-right">
                            <Link to="/contact" className="btn ss-btn" data-animation="fadeInUp" data-delay=".4s">
                                <i className="fas fa-angle-right" /> more service
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="timeline-active wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <Slider className="" {...sliderSettings}>
                                    {timelineItems.map((item, index) => (
                                        <div className="single-timeline" key={index}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="text">
                                                        <h3>{item.year}</h3>
                                                    </div>
                                                    <div className="text2">
                                                        <h5>{item.text1}</h5>
                                                        <p>{item.text2}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="text3">
                                                        <h5>{item.text1}</h5>
                                                        <p>{item.text2}</p>
                                                    </div>
                                                    <div className="text2">
                                                        <h3>{timelineItems[index + 1] ? timelineItems[index + 1].year : ''}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default History;
