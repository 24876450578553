import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Projectarea from '../MenProjectdetails/Projectarea'
import Footer from '../Footer/Main'

function Main() {
  return (
    <div>
        <Header/>
        <Breadcrumb mainheading={"Xolio Designer Portfolio"} sub={"Home"} title={"Service Details"}/>
        <Projectarea/>
        <Footer/>
    </div>
  )
}

export default Main