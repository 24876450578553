import React from 'react'
import { Link } from 'react-router-dom'
import Headertwo from '../Menhometwo/Headertwo'
import Homeslidertwo from '../Menhometwo/Homeslidertwo'
import Scrollbox from '../Menhometwo/Scrollbox'
import Abouttwo from '../Menhometwo/Abouttwo'
import Course from '../Menhometwo/Course'
import Hometwovideo from '../Menhometwo/Hometwovideo'
import Testimonial from '../Menhometwo/Testimonial'
import Data from '../Menhometwo/Data'
import Footer from '../Menhometwo/Footer'

function Main() {
  return (
    <>
      <main>
        <Headertwo />
        <Homeslidertwo />
        <Scrollbox />
        <Abouttwo />
        <Course />
        <Hometwovideo />
        <Testimonial />
        <Data />
        <Footer />
      </main>
    </>
  )
}

export default Main
