import React from 'react';
import { Link } from 'react-router-dom';
import features01 from '../../assets/img/features/mission-img.png';
import features02 from '../../assets/img/features/vision-img.png';
import features03 from '../../assets/img/features/mission-img.png';

const sections = [
    {
        imgSrc: features01,
        title: 'Our Mission',
    },
    {
        imgSrc: features02,
        title: 'Our Vision',
    },
    
];

function Mission() {
    const missionText = "We are committed to delivering exceptional quality and ensuring customer satisfaction. We believe in building strong relationships based on trust, reliability, and open communication. Understanding your unique requirements is our priority, allowing us to provide customized solutions that address your specific challenges and goals.";

    const visionText = "Innovating digital landscapes, empowering global transformation. Pioneering excellence through relentless innovation and collaboration.Driving technological advancement for sustainable growth.Transforming businesses, shaping a brighter digital future.";

    return (
        <>
            <section className="mission-area pb-90 p-relative">
                <div className="container">
                    <div className="row">
                        {sections.map((section, index) => (
                            <div className="col-lg-6 col-md-12" key={index}>
                                <div className="mission-box mb-30">
                                    <img src={section.imgSrc} alt="img" />
                                    <div className="text">
                                        <div className="title">
                                            <h3>{section.title}</h3>
                                            <p>{section.title === 'Our Mission' ? missionText : visionText}</p>
                                        </div>
                                        <Link to="/contact" className="btn ss-btn" data-animation="fadeInUp" data-delay=".4s">
                                            <i className="fas fa-angle-right" /> {section.buttonText}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}


export default Mission;
