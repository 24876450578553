import React from 'react';
import { Link } from 'react-router-dom';
import NumberCounter from 'number-counter';

function Aboutarea() {

  const sectionsData = [
    {
      title: 'Omani owned local business.',
      count: 100,
      duration: 4,
      description: 'Midklick System & Services is a 100% Omani owned local business.',
    },
    {
      title: 'Cost Savings',
      count: 45,
      duration: 2,
      description: 'We rapidly prototype and launch custom apps with no-code tools that move your business forward.',
    },
  ];

  return (
    <>
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8">
              <div className="section-title center-align mb-80 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                <h5>since from 2022</h5>
                <h2>We never compromise on security & quality. There is no doubt we get the job done right, every single time.</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <Link to="#" className="arrow-btn">
                <i className="fas fa-long-arrow-down"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Aboutarea;
