import React from 'react'
import features from '../../assets/img/features/why-choose-img.png'
import BG03 from '../../assets/img/features/why-choose-bg.png'

function Whyus() {
    return (
        <>
            <section className="why-choose pt-120 pb-120 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeIn animated" data-animation="fadeIn" data-delay=".4s" >
                            <img src={features} alt="img" />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInDown animated pr-30" data-animation="fadeInDown" data-delay=".4s" >
                            <div className="why-choose-content">
                                <div className="section-title wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                    <h5>why choose us</h5>
                                    <h2>A wonderful serenity has taken possession</h2>
                                </div>
                                <ul>
                                    <li>
                                        <i className="fas fa-check" /> Premium services and beyond your
                                        expectation
                                    </li>
                                    <li>
                                        <i className="fas fa-check" /> Get the best support among all
                                        venders
                                    </li>
                                    <li>
                                        <i className="fas fa-check" /> Great Price Competitive
                                    </li>
                                </ul>
                                <div className="why-choose-bg" style={{ backgroundImage: `url(${BG03})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center" }}>
                                    <h3>Are you ready for hire us!</h3>
                                    <strong>Make Appointment</strong>
                                </div>
                                <div className="opening">
                                    <div className="h">
                                        <h4>Opening Hours</h4>
                                    </div>
                                    <div className="time">
                                        Mon – Fri: 9:00AM – 6:00PM
                                        <br />
                                        Sat – Sun: 8:00AM – 4:00PM
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Whyus