import React from 'react'
import { Link } from 'react-router-dom'
import icon01 from '../../assets/img/icon/slider-icon01.png'
import icon02 from '../../assets/img/icon/slider-icon02.png'
import icon03 from '../../assets/img/icon/slider-icon03.png'
import icon04 from '../../assets/img/icon/slider-icon04.png'
import icon05 from '../../assets/img/icon/slider-icon05.png'

function Sliderservices() {
    return (
        <>
            <section className="slider-services p-relative fix">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="slider-services-content wow fadeInUp   animated" data-animation="fadeInUp" data-delay=".4s" style={{ visibility: "visible", animationName: "fadeInUp" }}>
                                <ul>
                                    <li>
                                        <div className="icon">
                                            <img src={icon01} alt="img" />
                                        </div>
                                        <Link to="#">Business Consultancy</Link>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={icon02} alt="img" />
                                        </div>
                                        <Link to="#">Business Planning</Link>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={icon03} alt="img" />
                                        </div>
                                        <Link to="#">ROI Innovation</Link>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={icon04} alt="img" />
                                        </div>
                                        <Link to="#">Private Solution</Link>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={icon05} alt="img" />
                                        </div>
                                        <Link to="#">ROI Tax</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sliderservices