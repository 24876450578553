import React from 'react'
import Header from '../Header/Main'
import Faqarea from '../Menfaq/Faqarea'
import Breadcrumb from '../Breadcrumb/Main'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"Faq Page"} sub={"Home"} title={"Faq"}/>
    <Faqarea/>
    <Footer/>
    </>
  )
}

export default Main