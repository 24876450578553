import React from 'react'
import { Link } from 'react-router-dom'
import Bg from '../../assets/img/bg/breadcrumb-bg.png'

function Breadcrumb({ sub, title, mainheading }) {
    return (
        <>
            <div>
                <section className="breadcrumb-area d-flex align-items-center" style={{ backgroundImage: `url(${Bg})`, backgroundSize: "cover" }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap mt-130">
                                    <div className="breadcrumb-title">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-xl-1 col-md-1">
                                                <hr />
                                            </div>
                                            <div className="col-xl-11 col-md-11">
                                                <h2>{mainheading}</h2>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-xl-1 col-md-1"></div>
                                            <div className="col-xl-11 col-md-11">
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <Link to="/">{sub}</Link>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            {title}
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Breadcrumb