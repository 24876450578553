import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Teamcontact from '../Mencontact/Teamcontact'
import Contactarea from '../Mencontact/Contactarea'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"Get In Touch"} sub={"Home"} title={"Contact"}/>
    <Teamcontact/>
    <Contactarea/>
    <Footer/>
    </>
  )
}

export default Main