import React from 'react'

function Contact() {
    return (
        <>
            <section className="contact-area contact-bg2 p-relative fix pt-120 pb-120" data-aos="fade-up" data-aos-duration={1000} >
                <div className="row">
                    <div className="col-lg-12 p-relative">
                        <div className="section-title center-align mb-80 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                            <h5>call to action</h5>
                            <h2>
                                Get An Amazing Quote For <br />
                                Your Business
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="container contact-bg02">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-bg">
                                <form action="mail.php" method="post" className="contact-form mt-30">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-name mb-15">
                                                <label>Your name</label>
                                                <input type="text" id="firstn" name="firstn" placeholder="First Name" required="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-15">
                                                <label>Your Eamil</label>
                                                <input type="text" id="email" name="email" placeholder="Eamil" required="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-subject mb-15">
                                                <label>Select Subject</label>
                                                <select name="services" id="sr">
                                                    <option value="select-subject">Select Subject</option>
                                                    <option value="business-consultancy">
                                                        Business Consultancy
                                                    </option>
                                                    <option value="business-design">Business Design</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-message mb-30">
                                                <label>Message</label>
                                                <textarea name="message" id="message" cols={30} rows={10} placeholder="Write comments" defaultValue={""} />
                                            </div>
                                            <div className="slider-btn">
                                                <button className="btn ss-btn active" data-animation="fadeInRight" data-delay=".8s" >
                                                    <i className="fas fa-angle-right" /> Submit Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212867.83634504632!2d-112.24455686962897!3d33.52582710700138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b743829374b03%3A0xabaac255b1e43fbe!2sPlexus%20Worldwide!5e0!3m2!1sen!2sin!4v1618567685329!5m2!1sen!2sin" width={600} height={500} style={{ border: 0 }} allowFullScreen="" loading="lazy" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact