import React from 'react'
import { Link } from 'react-router-dom'
import blog1 from '../../assets/img/blog/inner_b1.jpg'
import blog2 from '../../assets/img/blog/b_details01.jpg'
import blog3 from '../../assets/img/icon/c_d01.png'
import blog4 from '../../assets/img/blog/b_details03.jpg'
import blog5 from '../../assets/img/blog/b_details04.jpg'
import blog6 from '../../assets/img/blog/comment/avatar.png'
import blog7 from '../../assets/img/blog/comment/c_01.png'
import blog8 from '../../assets/img/blog/comment/c_02.png'
import blog9 from '../../assets/img/blog/comment/c_03.png'
import blog10 from '../../assets/img/blog/blog-ads-banner.png'

function Innerblogdtls() {
    return (
        <>
            <section className="single-post inner-blog b-details-p pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-details-wrap">
                                <div className="bsingle__post-thumb">
                                    <img src={blog1} alt="" />
                                </div>
                                <div className="details__content pb-30">
                                    <p>
                                        Business &amp; consultancy involves many different aspect, such as
                                        spatial planning, materials selection, construction techniques,
                                        and environmental considerations. Architects must consider the
                                        practical requirements of a building, such as its intended use,
                                        structural integrity, and safety, as well as its aesthetic
                                        qualities, such as form, color, and texture.
                                    </p>
                                    <h5>
                                        Now let’s set another scene — one that’s a bit less overwhelming.
                                    </h5>
                                    <p>
                                        A workday as organized as the second one is actually possible.
                                        &amp; there’s one major thing that’ll get you from chaos to smooth
                                        sailing in your design workflow: project management. Here are
                                        seven ways to stay organized and on top of your projects.
                                    </p>
                                    <p>
                                        <small>
                                            Thanks to our friends at Height for sponsoring this blog post!
                                        </small>
                                    </p>
                                    <p>
                                        In addition to designing buildings, architects may also be
                                        involved in urban planning, landscape architecture, and interior
                                        design. They may work in a variety of settings, including
                                        architectural firms, construction companies, government agencies,
                                        and academic institutions. Architects are professionals who
                                        specialize in designing buildings and other physical structures.
                                        They work with clients to understand their needs and preferences,
                                        and then use their expertise to create a design.
                                    </p>
                                    <div className="row justify-content-center align-items-center details__content-img">
                                        <div className="col-lg-6">
                                            <img src={blog2} alt="" />
                                        </div>
                                        <div className="col-lg-6">
                                            <h3>Tip #1: Record the process for repeat tasks.</h3>
                                            <p>
                                                A workday as organized as the second one is actually possible.
                                                &amp; there’s one major thing that’ll get you from chaos to
                                                smooth sailing in your design workflow: project management.
                                                Here are seven ways to stay organized.
                                            </p>
                                        </div>
                                    </div>
                                    <p>
                                        In addition to designing buildings, architects may also be
                                        involved in urban planning, landscape architecture, and interior
                                        design. They may work in a variety of settings, including
                                        architectural firms, construction companies, government agencies,
                                        and academic institutions. Architects are professionals who
                                        specialize in designing buildings and other physical structures.
                                        They work with clients to understand their needs and preferences,
                                        and then use their expertise to create a design.
                                    </p>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="post__tag">
                                                <h5>Releted Tags</h5>
                                                <ul>
                                                    <li>
                                                        <Link to="#">organic</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Foods</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">tasty</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="posts_navigation pt-35 pb-35">
                                    <div className="row align-items-center">
                                        <div className="col-xl-4 col-md-5">
                                            <div className="prev-link">
                                                <span>Prev Post</span>
                                                <h4>
                                                    <Link to="#">Tips Minimalist</Link>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-2 text-left text-md-center">
                                            <Link to="blog" className="blog-filter">
                                                <img src={blog3} alt="" />
                                            </Link>
                                        </div>
                                        <div className="col-xl-4 col-md-5">
                                            <div className="next-link text-left text-md-right">
                                                <span>next Post</span>
                                                <h4>
                                                    <Link to="#">Less Is More</Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="related__post mt-45 mb-85">
                                    <div className="post-title">
                                        <h4>Related Post</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="related-post-wrap mb-30">
                                                <div className="post-thumb">
                                                    <img src={blog4} alt="" />
                                                </div>
                                                <div className="rp__content">
                                                    <h3>
                                                        <Link to="#">
                                                            Auis nostrud exercita ullamco laboris nisi ut
                                                        </Link>
                                                    </h3>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                                                        sed do eiusmod temp or incididunt ut labore et dolore.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="related-post-wrap mb-30">
                                                <div className="post-thumb">
                                                    <img src={blog5} alt="" />
                                                </div>
                                                <div className="rp__content">
                                                    <h3>
                                                        <Link to="#">
                                                            Excepteur sint occaecat cupida tat non proident
                                                        </Link>
                                                    </h3>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                                                        sed do eiusmod temp or incididunt ut labore et dolore.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="avatar__wrap text-center mb-45">
                                    <div className="avatar-img">
                                        <img src={blog6} alt="" />
                                    </div>
                                    <div className="avatar__info">
                                        <h5>Rosalina William</h5>
                                        <div className="avatar__info-social">
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-behance" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-linkedin" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="avatar__wrap-content">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                            enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                            nisi ut aliquip ex ea commodo consequa aute irure dolor.
                                        </p>
                                    </div>
                                </div>
                                <div className="comment__wrap pb-45">
                                    <div className="comment__wrap-title">
                                        <h5>Comments</h5>
                                    </div>
                                    <div className="single__comment mb-35">
                                        <div className="comments-avatar">
                                            <img src={blog7} alt="" />
                                        </div>
                                        <div className="comment-text">
                                            <div className="avatar-name mb-15">
                                                <h6>ALina Kelian</h6>
                                                <span>19th May 2021</span>
                                            </div>
                                            <p>
                                                The bee's knees bite your arm off bits and bobs he nicked it
                                                gosh gutted mate blimey, old off his nut argy bargy vagabond
                                                buggered dropped.
                                            </p>
                                            <Link to="#" className="comment-reply mt-15">
                                                <i className="fas fa-reply" /> Reply
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single__comment children mb-35">
                                        <div className="comments-avatar">
                                            <img src={blog8} alt="" />
                                        </div>
                                        <div className="comment-text">
                                            <div className="avatar-name mb-15">
                                                <h6>
                                                    Rlex Kelian <i className="fas fa-bookmark" />
                                                </h6>
                                                <span>19th May 2021</span>
                                            </div>
                                            <p>
                                                Do one say wind up buggered bobby bite your arm off gutted
                                                mate, David victoria sponge cup of char chap fanny around.
                                            </p>
                                            <Link to="#" className="comment-reply mt-15">
                                                <i className="fas fa-reply" /> Reply
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single__comment">
                                        <div className="comments-avatar">
                                            <img src={blog9} alt="" />
                                        </div>
                                        <div className="comment-text">
                                            <div className="avatar-name mb-15">
                                                <h6>Roboto Alex</h6>
                                                <span>21th May 2021</span>
                                            </div>
                                            <p>
                                                Baking cakes is cobblers wellies William geeza bits and bobs
                                                what a plonker it's your round,
                                            </p>
                                            <Link to="#" className="comment-reply mt-15">
                                                <i className="fas fa-reply" /> Reply
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div id="comments" className="comments-area  mt-45">
                                    <div id="respond" className="comment-respond">
                                        <h3 id="reply-title" className="comment-reply-title">
                                            Leave a Reply{" "}
                                            <small>
                                                <Link
                                                    rel="nofollow"
                                                    id="cancel-comment-reply-link"
                                                    to="/finco/?p=2112#respond"
                                                    style={{ display: "none" }}
                                                >
                                                    Cancel reply
                                                </Link>
                                            </small>
                                        </h3>
                                        <form
                                            action="http://wordpress.zcube.in/finco/wp-comments-post.php"
                                            method="post"
                                            id="commentform"
                                            className="comment-form"
                                            noValidate=""
                                        >
                                            <p className="comment-notes">
                                                <span id="email-notes">
                                                    Your email address will not be published.
                                                </span>{" "}
                                                Required fields are marked <span className="required">*</span>
                                            </p>
                                            <p className="comment-field">
                                                <i className="fas fa-user" />
                                                <input
                                                    id="author"
                                                    placeholder="Your Name"
                                                    name="author"
                                                    type="text"
                                                />
                                            </p>
                                            <p className="comment-field">
                                                <i className="fas fa-envelope" />
                                                <input
                                                    id="email"
                                                    placeholder="your-real-email@example.com"
                                                    name="email"
                                                    type="text"
                                                />
                                            </p>
                                            <p className="comment-field">
                                                <i className="fas fa-globe" />
                                                <input
                                                    id="url"
                                                    name="url"
                                                    placeholder="http://your-site-name.com"
                                                    type="text"
                                                />{" "}
                                            </p>
                                            <p className="comment-form-comment">
                                                <label htmlFor="comment">Comment</label>{" "}
                                                <textarea
                                                    id="comment"
                                                    name="comment"
                                                    cols={45}
                                                    rows={8}
                                                    maxLength={65525}
                                                    required="required"
                                                    defaultValue={""}
                                                />
                                            </p>
                                            <p className="form-submit">
                                                <input
                                                    name="submit"
                                                    type="submit"
                                                    id="submit"
                                                    className="submit"
                                                    defaultValue="Post Comment"
                                                />{" "}
                                                <input
                                                    type="hidden"
                                                    name="comment_post_ID"
                                                    defaultValue={2112}
                                                    id="comment_post_ID"
                                                />
                                                <input
                                                    type="hidden"
                                                    name="comment_parent"
                                                    id="comment_parent"
                                                    defaultValue={0}
                                                />
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="search-3" className="widget widget_search">
                                    <h2 className="widget-title">Search</h2>
                                    <form
                                        role="search"
                                        method="get"
                                        className="search-form"
                                        action="http://wordpress.zcube.in/finco/"
                                    >
                                        <label>
                                            <span className="screen-reader-text">Search for:</span>
                                            <input
                                                type="search"
                                                className="search-field"
                                                placeholder="Search …"
                                                defaultValue=""
                                                name="s"
                                            />
                                        </label>
                                        <input
                                            type="submit"
                                            className="search-submit"
                                            defaultValue="Search"
                                        />
                                    </form>
                                </section>
                                <section
                                    id="custom_html-5"
                                    className="widget_text widget widget_custom_html"
                                >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-pinterest-p" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-wordpress" />
                                            </Link>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Categories</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="#">Branding</Link> (4)
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <Link to="#">Corporat</Link> (3)
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <Link to="#">Design</Link> (3)
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="#">Gallery</Link> (3)
                                        </li>
                                    </ul>
                                </section>
                                <section id="recent-posts-4" className="widget widget_recent_entries">
                                    <h2 className="widget-title">Recent Posts</h2>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                User Experience Psychology And Performance Smshing
                                            </Link>
                                            <span className="post-date">August 19, 2022</span>
                                        </li>
                                        <li>
                                            <Link to="#">Monthly Web Development Up Cost Of JavaScript</Link>
                                            <span className="post-date">August 19, 2022</span>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                There are many variation passages of like available.
                                            </Link>
                                            <span className="post-date">August 19, 2022</span>
                                        </li>
                                    </ul>
                                </section>
                                <section id="tag_cloud-1" className="widget widget_tag_cloud">
                                    <h2 className="widget-title">Tag</h2>
                                    <div className="tagcloud">
                                        <Link to="#" className="tag-cloud-link tag-link-28 tag-link-position-1" style={{ fontSize: "8pt" }} aria-label="app (1 item)" > app
                                        </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-17 tag-link-position-2" style={{ fontSize: "8pt" }} aria-label="Branding (1 item)" > Branding </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-20 tag-link-position-3" style={{ fontSize: "8pt" }} aria-label="corporat (1 item)" > corporat </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-24 tag-link-position-4" style={{ fontSize: "16.4pt" }} aria-label="Design (2 items)" > Design </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-25 tag-link-position-5" style={{ fontSize: "22pt" }} aria-label="gallery (3 items)" > gallery </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-26 tag-link-position-6" style={{ fontSize: "8pt" }} aria-label="video (1 item)" > video </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-29 tag-link-position-7" style={{ fontSize: "16.4pt" }} aria-label="web design (2 items)" > web design </Link>
                                    </div>
                                </section>
                                <section id="ads" className="widget widget_ads_banner">
                                    <img src={blog10} alt="blog-ads-banner" />
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Innerblogdtls