import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicedtlsarea from '../MenServicesdetails/Servicedtlsarea'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"Web Development"} sub={"Home"} title={"Service Details"}/>
    <Servicedtlsarea/>
    <Footer/>
    </>
  )
}

export default Main