import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Teamarea from '../MenTeamdetails/Teamarea'
import Skillsdtls from '../MenTeamdetails/Skillsdtls'
import Footer from '../Footer/Main'
function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"Personal Details"} sub={"Home"} title={"Single Team"}/>
    <Teamarea/>
    <Skillsdtls/>
    <Footer/>
    </>
  )
}

export default Main