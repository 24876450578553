import React from 'react';
import Slider from 'react-slick';
import Brand01 from '../../assets/img/brand/client-logo-01.png';
import Brand02 from '../../assets/img/brand/client-logo-02.png';
import Brand03 from '../../assets/img/brand/client-logo-03.png';
import Brand04 from '../../assets/img/brand/client-logo-04.png';
import Brand05 from '../../assets/img/brand/client-logo-05.png';
import Brand06 from '../../assets/img/brand/client-logo-06.png';

const brands = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 3,
                infinite: true,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const brandImages = [Brand01, Brand02, Brand03, Brand04, Brand05, Brand06];


function Brand() {

    return (
        <>
            <div className="brand-area2 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                <div className="container">
                    <Slider className="row brand-active pt-20 pb-20" {...brands}>
                        
                            {brandImages.map((brand, index) => (
                                <div className="col-xl-2" key={index}>
                                    <div className="client-item">
                                        <img src={brand} alt={`brand-${index + 1}`} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
            </div>
        </>
    );
}

export default Brand;
