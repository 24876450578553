import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import bg01 from '../../assets/img/bg/service-details-img01.png'
import icon01 from '../../assets/img/icon/intr-icon01.png'
import iconhover01 from '../../assets/img/icon/intr-icon01-hover.png'
import icon02 from '../../assets/img/icon/intr-icon02.png'
import iconhover02 from '../../assets/img/icon/intr-icon02-hover.png'
import icon03 from '../../assets/img/icon/intr-icon03.png'
import iconhover03 from '../../assets/img/icon/intr-icon03-hover.png'
import icon04 from '../../assets/img/icon/intr-icon04.png'
import iconhover04 from '../../assets/img/icon/intr-icon04-hover.png'
import BG1 from '../../assets/img/bg/video-bg3.png'

function Servicedtlsarea() {

    const [video, setVideo] = useState(false);

    return (
        <>
            <div className="about-area5 about-p p-relative">
                <div className="container pt-120 pb-90">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 order-1">
                            <div className="service-detail">
                                <div className="content-box">
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="image-column col-xl-12 col-lg-12 col-md-12">
                                                <figure className="image">
                                                    <img src={bg01} alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <h2> Serivce Overview </h2>
                                    <p>
                                        Architecture design is the process of planning and creating the
                                        structure, layout, and overall design of a building, landscape, or
                                        other physical space. It involves the integration of form,
                                        function, and aesthetics to create a cohesive and functional
                                        design that meets the needs of the users. The process of
                                        architecture design typically involves several steps, including
                                        research and analysis, conceptualization, schematic design, design
                                        development, and construction documentation. During each phase,
                                        the designer works with the client to refine and improve the
                                        design based on feedback and requirements. The final product of
                                        architecture design is a set of detailed drawings and
                                        specifications that guide the construction of the building or
                                        space. These drawings may include floor plans, elevations,
                                        sections, and details, as well as information about materials,
                                        finishes, and other construction details.
                                    </p>
                                    <p>
                                        Architecture design can be applied to a wide range of projects,
                                        from small residential homes to large commercial buildings, urban
                                        landscapes, and public spaces. The process of architecture design
                                        is critical to creating functional, aesthetically pleasing, and
                                        sustainable spaces that meet the needs of the users and the
                                        environment.{" "}
                                    </p>
                                    <h3>How It Works</h3>
                                    <p>
                                        Architecture design can be applied to a wide range of projects,
                                        from small residential homes to large commercial buildings, urban
                                        landscapes, and public spaces. The process of architecture design
                                        is critical to creating functional, aesthetically pleasing, and
                                        sustainable spaces that meet the needs of the users and the
                                        environment.{" "}
                                    </p>
                                    <div className="row mt-50 mb-50">
                                        <div className="col-lg-3 col-md-6">
                                            <div className="intregrations-item text-center mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                                <div className="intregrations-thumb">
                                                    <img src={icon01} alt="img" />
                                                    <img src={iconhover01} alt="img" className="hover" />
                                                </div>
                                                <div className="intregrations-content">
                                                    <h3>ROI Website</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="intregrations-item text-center mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                                <div className="intregrations-thumb">
                                                    <img src={icon02} alt="img" />
                                                    <img src={iconhover02} alt="img" className="hover" />
                                                </div>
                                                <div className="intregrations-content">
                                                    <h3>Android-Gen</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="intregrations-item text-center mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                                <div className="intregrations-thumb">
                                                    <img src={icon03} alt="img" />
                                                    <img src={iconhover03} alt="img" className="hover" />
                                                </div>
                                                <div className="intregrations-content">
                                                    <h3>IOS Software</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="intregrations-item text-center mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                                <div className="intregrations-thumb">
                                                    <img src={icon04} alt="img" />
                                                    <img src={iconhover04} alt="img" className="hover" />
                                                </div>
                                                <div className="intregrations-content">
                                                    <h3>Geforce RTX</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="s-video-wrap2 mb-50" style={{ backgroundImage: `url(${BG1})` }}>
                                        <div className="s-video-content text-center" onClick={() => setVideo(true)}>
                                            <h6>
                                                <Link to="" className="popup-video">play
                                                </Link>
                                            </h6>
                                        </div>
                                    </div>
                                    {video &&
                                        <>
                                            <div className="mfp-bg mfp-ready"></div>
                                            <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex={-1} style={{ overflow: "hidden auto" }} >
                                                <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                                                    <div className="mfp-content">
                                                        <div className="mfp-iframe-scaler">
                                                            <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)}>
                                                                ×
                                                            </button>
                                                            <iframe
                                                                className="mfp-iframe"
                                                                src="https://www.youtube.com/embed/6mpDNxI5gFY?autoplay=1"
                                                                frameBorder={0}
                                                                allowFullScreen=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mfp-preloader">Loading...</div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <p>
                                        Architecture design is the process of planning and creating the
                                        structure, layout, and overall design of a building, landscape, or
                                        other physical space. It involves the integration of form,
                                        function, and aesthetics to create a cohesive and functional
                                        design that meets the needs of the users. The process of
                                        architecture design typically involves several steps, including
                                        research and analysis, conceptualization, schematic design, design
                                        development, and construction documentation. During each phase,
                                        the designer works with the client to refine and improve the
                                        design based on feedback and requirements. The final product of
                                        architecture design is a set of detailed drawings and
                                        specifications that guide the construction of the building or
                                        space. These drawings may include floor plans, elevations,
                                        sections, and details, as well as information about materials,
                                        finishes, and other construction details.
                                    </p>
                                    <div className="services-nav">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <Link to="/service-details">
                                                    <i className="fas fa-long-arrow-left"></i> UI &amp; UX Design
                                                </Link>
                                            </div>
                                            <div className="col-lg-6 text-right">
                                                <Link to="/service-details">
                                                    SEO Service <i className="fas fa-long-arrow-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Servicedtlsarea