import React from 'react';
import intregrationsLine from '../../assets/img/bg/intregrations-line.png';
import icon01 from '../../assets/img/icon/intr-icon01.png';
import icon01hover from '../../assets/img/icon/intr-icon01-hover.png';
import icon02 from '../../assets/img/icon/intr-icon02.png';
import icon02hover from '../../assets/img/icon/intr-icon02-hover.png';
import icon03 from '../../assets/img/icon/intr-icon03.png';
import icon03hover from '../../assets/img/icon/intr-icon03-hover.png';
import icon04 from '../../assets/img/icon/intr-icon04.png';
import icon04hover from '../../assets/img/icon/intr-icon04-hover.png';
import icon05 from '../../assets/img/icon/intr-icon05.png';
import icon05hover from '../../assets/img/icon/intr-icon05-hover.png';
import icon06 from '../../assets/img/icon/intr-icon06.png';
import icon06hover from '../../assets/img/icon/intr-icon06-hover.png';
import icon07 from '../../assets/img/icon/intr-icon07.png';
import icon07hover from '../../assets/img/icon/intr-icon07-hover.png';

const integrationsData = [
    {
        icon: icon01,
        iconHover: icon01hover,
        title: 'Adhere to international standards for quality, safety, and business ethics.',
    },
    {
        icon: icon02,
        iconHover: icon02hover,
        title: 'providing systematic solutions to old and modern challenges.',
    },
    {
        icon: icon03,
        iconHover: icon03hover,
        title: 'Capable of swift adaption to market shifts on a worldwide scale.',
    },
    {
        icon: icon04,
        iconHover: icon04hover,
        title: 'Participating in good business practices.',
    },
];

function Intregrations() {
    return (
        <>
            <section className="intregrations-area pt-120 pb-90 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center align-items-center mb-80">
                        <div className="col-lg-6">
                            <div className="section-title center-align wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                <h5>MidKlick Strategy</h5>
                                <h2>Our people are our biggest asset</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={intregrationsLine} alt="img" className="intregrations-ani-01" />
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        {integrationsData.map((item, index) => (
                            <div key={index} className="col-lg-3 col-md-6">
                                <div className="intregrations-item text-center mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                    <div className="intregrations-thumb">
                                        <img src={item.icon} alt="img" />
                                        <img src={item.iconHover} alt="img" className="hover" />
                                    </div>
                                    <div className="intregrations-content">
                                        <h3>{item.title}</h3>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Intregrations;
