import React from 'react'
import { Link } from 'react-router-dom'
import shop01 from '../../assets/img/shop/img4.jpg'
import shop02 from '../../assets/img/shop/img5.jpg'
import shop03 from '../../assets/img/shop/img6.jpg'
import shop04 from '../../assets/img/shop/img7.jpg'
import shop05 from '../../assets/img/shop/img8.jpg'
import shop06 from '../../assets/img/shop/img9.jpg'
import shop07 from '../../assets/img/shop/img1.jpg'
import shop08 from '../../assets/img/shop/img2.jpg'
import shop09 from '../../assets/img/shop/img3.jpg'
import blogbanner from '../../assets/img/blog/blog-ads-banner.png'

function Shoparea() {
    return (
        <>
            <section className="shop-area pt-120 pb-120 p-relative "
                data-animation="fadeInUp animated"
                data-delay=".2s" >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <h6 className="mt-20 mb-30">Showing 1–9 of 10 results</h6>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-right">
                                    <select name="orderby" className="orderby" aria-label="Shop order">
                                        <option value="menu_order" selected="selected">
                                            Default sorting
                                        </option>
                                        <option value="popularity">Sort by popularity</option>
                                        <option value="rating">Sort by average rating</option>
                                        <option value="date">Sort by latest</option>
                                        <option value="price">Sort by price: low to high</option>
                                        <option value="price-desc">Sort by price: high to low</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-6">
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shop-details">
                                                <img src={shop01} alt="" />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shop-details">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shop-details">Jasper Morrison Cork Stool</Link>
                                            </h4>
                                            <div className="price">
                                                <span>$475.22</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shop-details">
                                                <img src={shop02} alt="" />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shop-details">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shop-details">Girona Chair</Link>
                                            </h4>
                                            <div className="price">
                                                <span>$490.51</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shop-details">
                                                <img src={shop03} alt="" />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shop-details">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shop-details">Saya Chair</Link>
                                            </h4>
                                            <div className="price">
                                                <span>$630.44</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shop-details">
                                                <img src={shop04} alt="" />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shop-details">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shop-details">Arco Dining Table</Link>
                                            </h4>
                                            <div className="price">
                                                <span>$219.78</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shop-details">
                                                <img src={shop05} alt="" />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shop-details">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shop-details">Eave Modular Sofa</Link>
                                            </h4>
                                            <div className="price">
                                                <span>$854.08</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shop-details">
                                                <img src={shop06} alt="" />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shop-details">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shop-details">D1 Desk</Link>
                                            </h4>
                                            <div className="price">
                                                <span>$446.61</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shop-details">
                                                <img src={shop07} alt="" />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shop-details">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shop-details">Farnsworth House Desk</Link>
                                            </h4>
                                            <div className="price">
                                                <span>$202.87</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shop-details">
                                                <img src={shop08} alt="" />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shop-details">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shop-details">Hopper Chair</Link>
                                            </h4>
                                            <div className="price">
                                                <span>$169.43</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shop-details">
                                                <img src={shop09} alt="" />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shop-details">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shop-details">Boba Pendant Lamp</Link>
                                            </h4>
                                            <div className="price">
                                                <span>$95.00</span>
                                                <span className="old-price">$778.35</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="pagination-wrap text-center">
                                        <nav>
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <Link to="#">
                                                        {" "}
                                                        <i className="fas fa-long-arrow-left" />
                                                    </Link>
                                                </li>
                                                <li className="page-item active">
                                                    <Link to="#">1</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#">2</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#">3</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#">...</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#">10</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#">
                                                        {" "}
                                                        <i className="fas fa-long-arrow-right" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-12">
                            <aside className="sidebar-widget">
                                <section id="search-3" className="widget widget_search">
                                    <h2 className="widget-title">Search</h2>
                                    <form role="search" method="get" className="search-form" action="http://wordpress.zcube.in/finco/" >
                                        <label>
                                            <span className="screen-reader-text">Search for:</span>
                                            <input type="search" className="search-field" placeholder="Search …" defaultValue="" name="s" />
                                        </label>
                                        <input type="submit" className="search-submit" defaultValue="Search" />
                                    </form>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Categories</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="#">Architecture</Link> (4)
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <Link to="#">Design</Link> (3)
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <Link to="#">Renovation</Link> (3)
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="#">Construction</Link> (3)
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="#">Soil</Link> (3)
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="#">Rebuild</Link> (3)
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="#">Interior</Link> (3)
                                        </li>
                                    </ul>
                                </section>
                                <section id="ads" className="widget widget_ads_banner">
                                    <img src={blogbanner} alt="blog-ads-banner" />
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Shoparea