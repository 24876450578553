import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

function Faqarea() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        if (index === activeIndex) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    const faqData = [
        {
            question: 'What are the essential elements of a successful website?',
            answer: 'Our community is being called to reimagine the future. As the only university where a renowned design school comes together with premier colleges, we are making learning more relevant and transformational. We are enriched by the wide range.',
        },
        {
            question: 'How do I choose a domain name for my website?',
            answer: 'Our community is being called to reimagine the future. As the only university where a renowned design school comes together with premier colleges, we are making learning more relevant and transformational. We are enriched by the wide range.',
        },
        {
            question: 'What is web hosting, and how does it work?',
            answer: 'Our community is being called...',
        },
        {
            question: 'How can I optimize my website for search engines (SEO)?',
            answer: 'Our community is being called...',
        },
        {
            question: 'What is a website?',
            answer: 'Our community is being called...',
        },
        {
            question: 'How do I create a website?',
            answer: 'Our community is being called...',
        },
        {
            question: 'How can I monetize my website?',
            answer: 'Our community is being called...',
        },
        {
            question: 'How do I track website traffic and analyze user behavior?',
            answer: 'Our community is being called...',
        }
    ];

    return (
        <>
            <section id="faq" className="faq-area pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="faq-contact-info mb-50">
                                <h4>Customer service</h4>
                                <ul>
                                    <li>
                                        <i className="fas fa-phone" />
                                        <span>
                                            <Link to="tel:+14440008888">+888 777 666 00</Link>
                                        </span>
                                    </li>
                                    <li>
                                        <i className="fas fa-envelope" />
                                        <span>
                                            <Link to="mailto:info@example.com">info@example.com</Link>
                                        </span>
                                    </li>
                                    <li>
                                        <i className="fas fa-map-marker" />
                                        <span>12/A, New Tower, New York, US</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="faq-link mb-50">
                                <h4>Frequently asked questions</h4>
                                <ul>
                                    <li>
                                        <Link to="#">
                                            <i className="fas fa-long-arrow-right" /> My Account
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="fas fa-long-arrow-right" /> Company Policies &amp;
                                            Procedure
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="fas fa-long-arrow-right" /> Payment options
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="fas fa-long-arrow-right" /> Gift Cart
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="fas fa-long-arrow-right" /> Terms &amp; Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="fas fa-long-arrow-right" /> How do I Login
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="fas fa-long-arrow-right" /> How Delivery works in
                                            Europe
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    {faqData.map((faq, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading${index}`}>
                                                <h2 className="mb-0">
                                                    <button className="faq-btn" icon={index === activeIndex ? faAngleUp : faAngleDown} type="button" onClick={() => toggleAccordion(index)}>
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div
                                                id={`collapse${index}`}
                                                className={`collapse ${index === activeIndex ? 'show' : ''}`}
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="card-body">{faq.answer}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Faqarea