import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Pricingarea from '../MenPricing/Pricingarea'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"Price & Plans"} sub={"Home"} title={"Price"}/>
    <Pricingarea/>
    <Footer/>
    </>
  )
}

export default Main