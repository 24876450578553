import React from 'react'
import { Link } from 'react-router-dom'
import shop01 from '../../assets/img/shop/details/thumb1.jpg'
import shop02 from '../../assets/img/shop/details/thumb2.jpg'
import shop03 from '../../assets/img/shop/details/thumb3.jpg'
import shop04 from '../../assets/img/shop/details/large1.jpg'
import shop05 from '../../assets/img/shop/details/large2.jpg'
import shop06 from '../../assets/img/shop/details/large3.jpg'

function Shopbanner() {
    return (
        <>
            <section className="shop-banner-area pt-120 pb-90 " data-animation="fadeInUp animated" data-delay=".2s" >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="shop-thumb-tab mb-30">
                                <ul className="nav" id="myTab2" role="tablist">
                                    <li className="nav-item">
                                        <Link className="nav-link active" id="home-tab" data-bs-toggle="tab" to="#home" role="tab" aria-selected="true" >
                                            <img src={shop01} alt="" />
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" id="profile-tab" data-bs-toggle="tab" to="#profile" role="tab" aria-selected="false" >
                                            <img src={shop02} alt="" />
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" id="profile-tab2" data-bs-toggle="tab" to="#profile1" role="tab" aria-selected="false" >
                                            <img src={shop03} alt="" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="product-details-img mb-30">
                                <div className="tab-content" id="myTabContent2">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" >
                                        <div className="product-large-img">
                                            <img src={shop04} alt="" />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile" role="tabpanel">
                                        <div className="product-large-img">
                                            <img src={shop05} alt="" />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile1" role="tabpanel">
                                        <div className="product-large-img">
                                            <img src={shop06} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="product-details mb-30">
                                <div className="product-details-title">
                                    <p>Workstead</p>
                                    <h1>Helios Piranho Lamp</h1>
                                    <div className="price details-price pb-30 mb-20">
                                        <span>$700.00</span>
                                        <span className="old-price">$820.00</span>
                                    </div>
                                </div>
                                <p>
                                    It is a long established fact that a reader will be distracted by
                                    the readable content of a page when looking at its layout. The point
                                    of using Lorem Ipsum is that it has a more-or-less normal
                                    distribution of letters, as opposed to using 'Content here, content
                                    here', making it look like readable English.
                                </p>
                                <div className="product-cat mt-30 mb-30">
                                    <span>Category: </span>
                                    <Link to="#">furniture,</Link>
                                    <Link to="#">decor</Link>
                                </div>
                                <div className="product-details-action">
                                    <form action="#">
                                        <div className="plus-minus">
                                            <div className="cart-plus-minus">
                                                <input type="text" defaultValue={1} />
                                            </div>
                                        </div>
                                        <button className="btn btn-black" type="submit">
                                            add to cart
                                        </button>
                                    </form>
                                </div>
                                <div className="product-social mt-45">
                                    <Link to="#">
                                        <i className="fab fa-facebook-f" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-twitter" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-behance" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-linkedin-in" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-youtube" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Shopbanner