import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Aboutarea from '../MenAbout/Aboutarea'
import Mission from '../MenAbout/Mission'
import History from '../MenAbout/History'
import Insightarea from '../MenAbout/Insightarea'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"About MIDKLICK"} sub={"Home"} title={"About Us"}/>
    <Aboutarea/>
    <Mission/>
    <Insightarea/>
    <Footer/>
    </>
  )
}

export default Main