import React from 'react'
import { Link } from 'react-router-dom'

function contact() {
    return (
        <>
            <section className="cta-area cta-bg pt-30 pb-30" style={{ background: "#DCE864" }} >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-10">
                            <div className="section-title cta-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s" >
                                <h2>Are ready to give us a creative product?</h2>
                            </div>
                        </div>
                        <div className="col-lg-2 text-right">
                            <div className="cta-btn2 s-cta-btn wow fadeInRight animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <Link to="/contact" className="btn ss-btn smoth-scroll">
                                    <i className="fas fa-angle-right" /> make request
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default contact
