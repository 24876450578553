import React from 'react'
import { Link } from 'react-router-dom'
import BG04 from '../../assets/img/slider/slider_bg_03.png'

function Homesliderthree() {
    return (
        <>
            <section id="home" className="slider-area fix p-relative">
                <div className="slider-active" style={{ background: "#000" }}>
                    <div className="single-slider home-slider-3 slider-bg d-flex align-items-center" style={{ backgroundImage: `url(${BG04})`, backgroundSize: "cover", backgroundPosition: "center center" }} >
                        <div className="s-overlay" />
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-8 col-md-12">
                                    <div className="slider-content s-slider-content">
                                        <h5 data-animation="fadeInUp" data-delay=".4s">
                                            We Help Organize
                                        </h5>
                                        <h2 data-animation="fadeInUp" data-delay=".4s">
                                            Business Solution
                                        </h2>
                                        <div className="slider-btn mt-30 mb-105">
                                            <Link to="/contact" className="btn ss-btn active mr-15" data-animation="fadeInUp" data-delay=".4s" >
                                                <i className="fas fa-angle-right" /> learn more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Homesliderthree