import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Shopbanner from '../MenShopdetails/Shopbanner'
import Product from '../MenShopdetails/Product'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"Shop Deatlis"} sub={"Home"} title={"Shop Deatlis"}/>
    <Shopbanner/>
    <Product/>
    <Footer/>
    </>
  )
}

export default Main