import React from 'react'
import { Link } from 'react-router-dom'
import contact01 from '../../assets/img/bg/cta-ani-01.png';
import contact02 from '../../assets/img/bg/cta-ani-02.png';
import contact03 from '../../assets/img/bg/cta-ani-03.png';
import contact04 from '../../assets/img/bg/cta-ani-04.png';
import contacticon from '../../assets/img/bg/cta-icon.png';

function contact() {
    return (
        <>
            <section className="cta-area about-p pt-120 pb-120 p-relative fix" style={{ background: "#FCF6F0" }}>
                <div className="animations-01">
                    <img src={contact01} alt="contact-bg-an-01" />
                </div>
                <div className="animations-02">
                    <img src={contact02} alt="contact-bg-an-01" />
                </div>
                <div className="animations-03">
                    <img src={contact03} alt="contact-bg-an-01" />
                </div>
                <div className="animations-04">
                    <img src={contact04} alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-9">
                            <div className="section-title center-align mb-40 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <div className="mb-40">
                                    <img src={contacticon} alt="contact-bg-an-01" />
                                </div>
                                <h2>
                                    Have a new project ? <br />
                                    Reach us at <Link to="contact">info@midklick.com</Link>
                                </h2>
                                <p>
                                    We are open to suggestion and very flexible when it comes to feedback about the company.
                                </p>
                                <p> Have a talk with us.</p>
                            </div>
                            <div className="slider-btn text-center">
                                <Link to="/contact" className="btn ss-btn" data-animation="fadeInUp" data-delay=".4s" >
                                    <i className="fas fa-angle-right" /> Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default contact