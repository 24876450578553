import React from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import protfolio01 from '../../assets/img/gallery/protfolio-img01.jpg';
import protfolio02 from '../../assets/img/gallery/protfolio-img02.jpg';
import protfolio03 from '../../assets/img/gallery/protfolio-img03.jpg';
import '@fortawesome/fontawesome-free/css/all.min.css';

const home = {
  speed: 1000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

function Portfolio() {
  return (
    <>
      <section id="portfolio" className="pt-120 pb-110 fix" style={{ background: "#fff" }} >
        <div className="container">
          <div className="portfolio ">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="section-title p-relative mb-50 wow fadeInLeft  animated" data-animation="fadeInLeft" data-delay=".4s" >
                  <h5>portfolio</h5>
                  <h2>Recent &amp; featured works</h2>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-right">
                <Link to="/contact" className="btn ss-btn" data-animation="fadeInUp" data-delay=".4s" >
                  <i className="fas fa-angle-right" /> more service
                </Link>
              </div>
            </div>
            <Swiper className="home-blog-active wow fadeInUp animated slick-initialized slick-slider slick-dotted" {...home} spaceBetween={10} slidesPerView={3} data-animation="fadeInUp" data-delay=".4s" >
              <SwiperSlide className="grid-item financial">
                <Link to="/project-details">
                  <figure className="gallery-image">
                    <img src={protfolio01} alt="img" className="img" />
                    <figcaption>
                      <span>Design, App</span>
                      <h4>
                        <i className="fas fa-long-arrow-right"></i>
                        Alex Neural AI App Design
                      </h4>
                    </figcaption>
                  </figure>
                </Link>
              </SwiperSlide>
              <SwiperSlide className="grid-item financial banking">
                <Link to="/project-details">
                  <figure className="gallery-image">
                    <img src={protfolio02} alt="img" className="img" />
                    <figcaption>
                      <span>Website, Dashboard</span>
                      <h4>
                        <i className="fas fa-long-arrow-right"></i>
                        iPhone Website Design
                      </h4>
                    </figcaption>
                  </figure>
                </Link>
              </SwiperSlide>
              <SwiperSlide className="grid-item insurance">
                <Link to="/project-details">
                  <figure className="gallery-image">
                    <img src={protfolio03} alt="img" className="img"/>
                    <figcaption>
                      <span>UI, UX, iOs</span>
                      <h4>
                        <i className="fas fa-long-arrow-right"></i>
                        Call Of Duty Mobile
                      </h4>
                    </figcaption>
                  </figure>
                </Link>
              </SwiperSlide>
              <SwiperSlide className="grid-item financial">
                <Link to="/project-details">
                  <figure className="gallery-image">
                    <img
                      src={protfolio01}
                      alt="img"
                      className="img"
                    />
                    <figcaption>
                      <span>Design, App</span>
                      <h4>
                        <i className="fas fa-long-arrow-right"></i>
                        Alex Neural AI App Design
                      </h4>
                    </figcaption>
                  </figure>
                </Link>
              </SwiperSlide>
              <SwiperSlide className="grid-item financial banking">
                <Link to="/project-details">
                  <figure className="gallery-image">
                    <img src={protfolio02} alt="img" className="img" />
                    <figcaption>
                      <span>Website, Dashboard</span>
                      <h4>
                        <i className="fas fa-long-arrow-right"></i>
                        iPhone Website Design
                      </h4>
                    </figcaption>
                  </figure>
                </Link>
              </SwiperSlide>
              <SwiperSlide className="grid-item insurance">
                <Link to="/project-details">
                  <figure className="gallery-image">
                    <img src={protfolio03} alt="img" className="img" />
                    <figcaption>
                      <span>UI, UX, iOs</span>
                      <h4>
                        <i className="fas fa-long-arrow-right"></i>
                        Call Of Duty Mobile
                      </h4>
                    </figcaption>
                  </figure>
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default Portfolio;
