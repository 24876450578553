import React , {useState} from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logo/logo-w.png'

function Headerthree() {

    const [homeDrop, sethomeDrop] = useState(false)
    const [services, setservices] = useState(false);
    const [Pages, setPages] = useState(false);
    const [nav, setnav] = useState(false);

    return (
        <>
            <header className="header-area header-two">
                <div className="header-top second-header d-none d-md-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 d-none d-lg-block">
                                <div className="header-social">
                                    <span>
                                        <Link to="#" title="Facebook">
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" title="LinkedIn">
                                            <i className="fab fa-instagram" />
                                        </Link>
                                        <Link to="#" title="Twitter">
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#" title="Twitter">
                                            <i className="fab fa-youtube" />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 d-none d-lg-block mt-10 mb-10 text-right">
                                <div className="header-cta">
                                    <ul>
                                        <li>
                                            <span>info@gentech.com</span>
                                        </li>
                                        <li>
                                            <span>+888 777 660 55</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-2 col-lg-2">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={logo} alt="logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-10 col-lg-10">
                                <div className="main-menu text-right text-xl-right">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li className="has-sub">
                                                <Link to="/">Home</Link>
                                                <ul>
                                                    <li>
                                                        <Link to="/">Home Page 01</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/home-two">Home Page 02</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/home-three">Home Page 03</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/about">About</Link>
                                            </li>
                                            <li className="has-sub">
                                                <Link to="/services">Services</Link>
                                                <ul>
                                                    <li>
                                                        {" "}
                                                        <Link to="/services">Services</Link>
                                                    </li>
                                                    <li>
                                                        {" "}
                                                        <Link to="/service-details">Services Details</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="has-sub">
                                                <Link to="#">Pages</Link>
                                                <ul>
                                                    <li>
                                                        <Link to="/blog">Blog</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/blog-details">Blog Details</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/faq">Faq</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/team">Team</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/team-details">Team Details</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/pricing">Pricing</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/shop">Shop</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/shop-details">Shop Details</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/projects">Portfolio</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact">Contact</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mobile-menu mean-container">
                                    <div className="mean-bar">
                                        <a href="#nav" className={nav ? "meanmenu-reveal meanclose" : "meanmenu-reveal "} style={{ background: "", color: "", right: 0, left: "auto", textAlign: "center", textIndent: "0", fontSize: "18px" }} onClick={() => setnav(nav ? false : true)}>
                                            {nav ? "X" : (
                                                <span>
                                                    <span>
                                                        <span />
                                                    </span>
                                                </span>
                                            )}
                                        </a>
                                        {nav &&
                                            <nav className="mean-nav">
                                                <ul style={{ display: "block" }}>
                                                    <li className="has-sub">
                                                        <Link to="/">Home</Link>
                                                        {homeDrop &&
                                                            <ul style={{ display: "block" }}>
                                                                <li>
                                                                    <Link to="/">Home Page 01</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/home-two">Home Page 02</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/home-three">Home Page 03</Link>
                                                                </li>
                                                            </ul>
                                                        }
                                                        <Link className={homeDrop ? "mean-expand mean-clicked" : "mean-expand"} to="#" style={{ fontSize: 18 }} onClick={() => sethomeDrop(homeDrop ? false : true)}>
                                                            {homeDrop ? "-" : "+"}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/about">About</Link>
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="/services">Services</Link>
                                                        {services &&
                                                            <ul style={{ display: "block" }}>
                                                                <li>
                                                                    {" "}
                                                                    <Link to="/services">Services</Link>
                                                                </li>
                                                                <li>
                                                                    {" "}
                                                                    <Link to="/service-details">Services Details</Link>
                                                                </li>
                                                            </ul>
                                                        }
                                                        <Link className={services ? "mean-expand mean-clicked" : "mean-expand"} to="#" style={{ fontSize: 18 }} onClick={() => setservices(services ? false : true)}>
                                                            {services ? "-" : "+"}
                                                        </Link>
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="#">Pages</Link>
                                                        {Pages &&
                                                            <ul style={{ display: "block" }}>
                                                                <li>
                                                                    <Link to="/blog">Blog</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/blog-details">Blog Details</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/faq">Faq</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/team">Team</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/team-details">Team Details</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/pricing">Pricing</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/shop">Shop</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/shop-details">Shop Details</Link>
                                                                </li>
                                                            </ul>
                                                        }
                                                        <Link className={Pages ? "mean-expand mean-clicked" : "mean-expand"} to="#" style={{ fontSize: 18 }} onClick={() => setPages(Pages ? false : true)}>
                                                            {Pages ? "-" : "+"}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/projects">Portfolio</Link>
                                                    </li>
                                                    <li className="mean-last">
                                                        <Link to="/contact">Contact</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <>
                <div className="offcanvas-menu">
                    <span className="menu-close">
                        <i className="fas fa-times" />
                    </span>
                    <form role="search" method="get" id="searchform" className="searchform" action="http://wordpress.zcube.in/xconsulta/" >
                        <input type="text" name="s" id="search" placeholder="Search" />
                        <button>
                            <i className="fa fa-search" />
                        </button>
                    </form>
                    <div id="cssmenu3" className="menu-one-page-menu-container">
                        <ul className="menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/about">About Us</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="#">Menu</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/pricing">Pricing </Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/team">Teacher</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/projects">Gallery</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/blog">Blog</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div id="cssmenu2" className="menu-one-page-menu-container">
                        <ul id="menu-one-page-menu-12" className="menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="#home">
                                    <span>+8 12 3456897</span>
                                </Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="#howitwork">
                                    <span>info@example.com</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="offcanvas-overly" />
            </>
        </>
    )
}

export default Headerthree