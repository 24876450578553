import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logo/logo-w.png'

function Footer() {
  return (
    <>
      <footer className="footer-bg3 footer-p">
        <div className="footer-top  pt-90 pb-40" style={{ backgroundColor: "#20304F" }} >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-8 col-lg-6 col-sm-4">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title mb-50">
                    <img src={logo} alt="img" />
                  </div>
                  <div className="f-contact">
                    <ul>
                      <li>
                        <h5>
                          1247/Plot No. 39, 15th Phase,
                          <br /> LHB Colony, Kanpur
                        </h5>
                      </li>
                      <li>
                        info@gentech-info.com
                        <br />
                        +888 777 666 00
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-4">
                <div className="footer-widget mb-30 bdr text-right">
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/"> About Us</Link>
                      </li>
                      <li>
                        <Link to="/">Services </Link>
                      </li>
                      <li>
                        <Link to="/">Company </Link>
                      </li>
                      <li>
                        <Link to="/">Pricing </Link>
                      </li>
                      <li>
                        <Link to="/">Insights </Link>
                      </li>
                      <li>
                        <Link to="/contact"> Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-4">
                <div className="footer-widget mb-30">
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Style Guide</Link>
                      </li>
                      <li>
                        <Link to="/">Licenses</Link>
                      </li>
                      <li>
                        <Link to="/">Changlog</Link>
                      </li>
                      <li>
                        <Link to="/">Account</Link>
                      </li>
                      <li>
                        <Link to="/">Custom Order</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">Copyright &amp; design By @ZcubeDesign</div>
              <div className="col-lg-6 text-right">
                <Link to="#">Careers</Link> / <Link to="#">Signup</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer