import React from 'react'
import { Link } from 'react-router-dom'
import team1 from '../../assets/img/team/team09.png'
import team2 from '../../assets/img/team/team10.png'
import team3 from '../../assets/img/team/team11.png'
import team4 from '../../assets/img/team/team12.png'
import team5 from '../../assets/img/team/team13.png'
import team6 from '../../assets/img/team/team14.png'
import team7 from '../../assets/img/team/team15.png'
import team8 from '../../assets/img/team/team16.png'

function Teamarea() {
    return (
        <>
            <section className="team-area fix p-relative pt-120 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="single-team mb-40 p-relative">
                                <div className="team-social">
                                    <Link to="#" className="share-alt">
                                        <i className="fas fa-share-alt" />
                                    </Link>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="team-thumb">
                                    <div className="brd">
                                        <Link to="/team-details">
                                            {" "}
                                            <img src={team1} alt="img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="team-info">
                                    <span>Founder</span>
                                    <h4>
                                        <Link to="/team-details">Hilixer D. Dowson</Link>
                                    </h4>
                                    <p>
                                        The first step in the design process is to research and analysis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="single-team mb-40 p-relative">
                                <div className="team-social">
                                    <Link to="#" className="share-alt">
                                        <i className="fas fa-share-alt" />
                                    </Link>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="team-thumb">
                                    <div className="brd">
                                        <Link to="/team-details">
                                            {" "}
                                            <img src={team2} alt="img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="team-info">
                                    <span>CEO</span>
                                    <h4>
                                        <Link to="/team-details">Rosalina D. William</Link>
                                    </h4>
                                    <p>
                                        The first step in the design process is to research and analysis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="single-team mb-40 p-relative">
                                <div className="team-social">
                                    <Link to="#" className="share-alt">
                                        <i className="fas fa-share-alt" />
                                    </Link>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="team-thumb">
                                    <div className="brd">
                                        <Link to="/team-details">
                                            {" "}
                                            <img src={team3} alt="img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="team-info">
                                    <span>Head Of Design</span>
                                    <h4>
                                        <Link to="/team-details">Alonso S. Silson</Link>
                                    </h4>
                                    <p>
                                        The first step in the design process is to research and analysis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="single-team mb-40 p-relative">
                                <div className="team-social">
                                    <Link to="#" className="share-alt">
                                        <i className="fas fa-share-alt" />
                                    </Link>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="team-thumb">
                                    <div className="brd">
                                        <Link to="/team-details">
                                            {" "}
                                            <img src={team4} alt="img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="team-info">
                                    <span>Consultant</span>
                                    <h4>
                                        <Link to="/team-details">Humble H. Hilixer</Link>
                                    </h4>
                                    <p>
                                        The first step in the design process is to research and analysis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="single-team mb-40 p-relative">
                                <div className="team-social">
                                    <Link to="#" className="share-alt">
                                        <i className="fas fa-share-alt" />
                                    </Link>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="team-thumb">
                                    <div className="brd">
                                        <Link to="/team-details">
                                            {" "}
                                            <img src={team5} alt="img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="team-info">
                                    <span>Designer</span>
                                    <h4>
                                        <Link to="/team-details">Lixer D. Dowson</Link>
                                    </h4>
                                    <p>
                                        The first step in the design process is to research and analysis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="single-team mb-40 p-relative">
                                <div className="team-social">
                                    <Link to="#" className="share-alt">
                                        <i className="fas fa-share-alt" />
                                    </Link>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="team-thumb">
                                    <div className="brd">
                                        <Link to="/team-details">
                                            {" "}
                                            <img src={team6} alt="img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="team-info">
                                    <span>Developer</span>
                                    <h4>
                                        <Link to="/team-details">Rosa D. William</Link>
                                    </h4>
                                    <p>
                                        The first step in the design process is to research and analysis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="single-team mb-40 p-relative">
                                <div className="team-social">
                                    <Link to="#" className="share-alt">
                                        <i className="fas fa-share-alt" />
                                    </Link>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="team-thumb">
                                    <div className="brd">
                                        <Link to="/team-details">
                                            {" "}
                                            <img src={team7} alt="img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="team-info">
                                    <span>Support Engineer</span>
                                    <h4>
                                        <Link to="/team-details">Lonso Silson</Link>
                                    </h4>
                                    <p>
                                        The first step in the design process is to research and analysis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="single-team mb-40 p-relative">
                                <div className="team-social">
                                    <Link to="#" className="share-alt">
                                        <i className="fas fa-share-alt" />
                                    </Link>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="team-thumb">
                                    <div className="brd">
                                        <Link to="/team-details">
                                            {" "}
                                            <img src={team8} alt="img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="team-info">
                                    <span>Support Engineer</span>
                                    <h4>
                                        <Link to="/team-details">Elina H. Hilixer</Link>
                                    </h4>
                                    <p>
                                        The first step in the design process is to research and analysis
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Teamarea