import React from 'react'
import { Link } from 'react-router-dom'
import features01 from '../../assets/img/features/about-img4.png'

function Aboutarea() {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        {/* <div className="col-lg-12">
                            <img src={features01} alt="img" />
                        </div> */}
                        <div className="col-lg-8">
                            <div className="section-title center-align mt-80 mb-80 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <h5>Company Overview</h5>
                                <h5>
                                MIDKLICK LLC is a company that specializes in bringing the digital world to life. Our diverse range of
services includes Software as a Service (SaaS), software development, ICT solutions, documentation,
and mobile and web app design. Since our establishment in 2022, we have been dedicated to
enhancing the value of technology in Oman and beyond
                                </h5>
                                <div>...</div>
                                <h5>At MIDKLICK, our founders Rami AlShaqsi and Said AlLamki are key to our success. Their tech
expertise and passion drive our innovation. With Waleed AlKharusi, our experienced business
consultant, we shape tailored solutions for clients. Together, we deliver custom solutions to meet your
needs</h5>
<div>...</div>
                           <h5></h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <Link to="ourprojects" className="arrow-btn">
                            <i className="fas fa-long-arrow-down faa-vertical animated"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutarea