import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Teamarea from '../MenTeam/Teamarea'
import Teamskills from '../MenTeam/Teamskills'
import Portfolio from '../Menhome/Portfolio'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"Our Avengers"} sub={"Home"} title={"Team"}/>
    <Teamarea/>
    <Teamskills/>
    <Portfolio/>
    <Footer/>
    </>
  )
}

export default Main