import React from 'react';
import { Link } from 'react-router-dom';
import team01 from '../../assets/img/team/team09.png';
import team02 from '../../assets/img/team/team10.png';
import team03 from '../../assets/img/team/team11.png';
import team04 from '../../assets/img/team/team12.png';

const teamMembers = [
    {
        image: team01,
        role: 'Founder',
        name: 'Hilixer D. Dowson',
        description: 'The first step in the design process is to research and analysis',
    },
    {
        image: team02,
        role: 'CEO',
        name: 'Rosalina D. William',
        description: 'The first step in the design process is to research and analysis',
    },
    {
        image: team03,
        role: 'Head Of Design',
        name: 'Alonso S. Silson',
        description: 'The first step in the design process is to research and analysis',
    },
    {
        image: team04,
        role: 'Consultant',
        name: 'Humble H. Hilixer',
        description: 'The first step in the design process is to research and analysis',
    },
];

function Teamarea() {
    return (
        <>
            <section className="team-area fix p-relative pt-120 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 p-relative">
                            <div className="section-title center-align mb-50 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <h5>management</h5>
                                <h2>Our Expert Avengers</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {teamMembers.map((teamMember, index) => (
                            <div className="col-xl-3 col-md-6" key={index}>
                                <div className="single-team mb-40 p-relative">
                                    <div className="team-social">
                                        <Link to="#" className="share-alt">
                                            <i className="fas fa-share-alt" />
                                        </Link>
                                        <ul>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-facebook-f" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-instagram" />
                                                </Link>
                                            </li>
                                            <li>
                                                {" "}
                                                <Link to="#">
                                                    <i className="fab fa-twitter" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="team-thumb">
                                        <div className="brd">
                                            <Link to="/team-details">
                                                {" "}
                                                <img src={teamMember.image} alt="img" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="team-info">
                                        <span>{teamMember.role}</span>
                                        <h4>
                                            <Link to="/team-details">{teamMember.name}</Link>
                                        </h4>
                                        <p>{teamMember.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Teamarea;
