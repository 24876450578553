import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Contact from '../MenThankyou/contact'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
      <Header />
      <Breadcrumb mainheading={"Thankyou"} sub={"Home"} title={"Thank you"} />
      <Contact />
      <Footer />
    </>
  )
}

export default Main