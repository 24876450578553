import React from 'react'
import { Link } from 'react-router-dom'
import Slider01 from '../../assets/img/slider/slider-img2.png'

function Homeslider02() {
    return (
        <>
            <section id="home" className="slider-area2 pb-120 fix p-relative" style={{ background: "#FCF6F0" }} >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="slider-content2 mt-50 mb-100">
                                <h2 data-animation="fadeInUp" data-delay=".4s">
                                    MidKLICK.
                                </h2>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h5>data science</h5>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <h5>since from 2000</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-end">
                        <div className="col-lg-6 col-md-6">
                            <div className="slider-header-nav">
                                <ul>
                                    <li>
                                        <Link to="#">
                                            <span>01</span> <span className="text-right">Data Science</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span>02</span>{" "}
                                            <span className="text-right">AI &amp; Machine Learning</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span>03</span>{" "}
                                            <span className="text-right">Big Data Soltuions</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span>04</span>{" "}
                                            <span className="text-right">Real-time Data Solutions</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="slider-img">
                                <img src={Slider01} alt="contact-bg-an-01" />
                                <div className="slider-btn">
                                    <Link to="/contact" className="btn ss-btn active" data-animation="fadeInUp" data-delay=".4s" >
                                        <i className="fas fa-angle-right" /> start learning
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Homeslider02