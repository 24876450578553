import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import feature from '../../assets/img/features/badge.png'
import feature2 from '../../assets/img/features/badge2.png'
import BG from '../../assets/img/bg/about-bg.png'
import BG0 from '../../assets/img/bg/video-bg2.png'

function Aboutthree() {

    const [video, setVideo] = useState(false)

    return (
        <>
            <section className="about-area3 about-p pt-120 p-relative fix" style={{ backgroundImage: `url(${BG})`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  text-center wow fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                <div className="section-title pb-25 ">
                                    <h5>
                                        <img src={feature} alt="img" />
                                    </h5>
                                    <h2>We're help you turn your idea into a great product</h2>
                                </div>
                                <div className="slider-btn mt-30 mb-80 wow fadeInDown animated pr-30" data-animation="fadeInDown" data-delay=".4s" >
                                    <Link to="/contact" className="btn ss-btn active mr-15" data-animation="fadeInUp" data-delay=".4s" >
                                        <i className="fas fa-angle-right" /> case study
                                    </Link>
                                    <Link to="/contact" className="btn ss-btn" data-animation="fadeInUp" data-delay=".4s">
                                        <i className="fas fa-angle-right" /> get in touch
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-120 wow fadeInDown animated pr-30" data-animation="fadeInDown" data-delay=".4s" >
                            <img src={feature2} alt="img" />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInUp animated pr-30" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="s-video-wrap2" style={{ backgroundImage: `url(${BG0})`}}>
                                <div className="s-video-content text-center" onClick={() => setVideo(true)}>
                                    <h6>
                                        <Link to="" className="popup-video" >play
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {video &&
                <>
                    <div className="mfp-bg mfp-ready"></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex={-1} style={{ overflow: "hidden auto" }} >
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)}>
                                        ×
                                    </button>
                                    <iframe
                                        className="mfp-iframe"
                                        src="https://www.youtube.com/embed/6mpDNxI5gFY?autoplay=1"
                                        frameBorder={0}
                                        allowFullScreen=""
                                    />
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default Aboutthree