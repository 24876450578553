import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import blog from '../../assets/img/blog/inner_b1.jpg'
import blog01 from '../../assets/img/blog/inner_b2.jpg'
import blog02 from '../../assets/img/blog/inner_b3.jpg'
import blog03 from '../../assets/img/blog/inner_b2.jpg'
import blog04 from '../../assets/img/blog/inner_b1.jpg'
import blog05 from '../../assets/img/blog/inner_b5.jpg'
import blog06 from '../../assets/img/blog/inner_b6.jpg'
import blogbanner from '../../assets/img/blog/blog-ads-banner.png'

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <>
            <button type="button" className="slick-next slick-arrow" onClick={onClick}>
                <i className="fas fa-long-arrow-right"></i>
            </button>
        </>
    );
}

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <>
            <button type="button" className="slick-prev slick-arrow" onClick={onClick}>
                <i className="fas fa-long-arrow-left"></i>
            </button>
        </>
    );
}

const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
};

function Innerblog() {
    const [video, setVideo] = useState(false);
    return (
        <>
            <section className="inner-blog pt-120 pb-105">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="bsingle__post mb-50">
                                <div className="bsingle-cs">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Link to="#">Business</Link>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <div className="date-home2">
                                                <i className="fas fa-calendar-alt" /> October 20, 2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bsingle__post-thumb">
                                    <img src={blog} alt="" />
                                </div>
                                <div className="bsingle__content">
                                    <h2>
                                        <Link to="/blog-details">
                                            This advice emphasizes the importance of maintaining a curious
                                        </Link>
                                    </h2>
                                    <div className="blog__btn">
                                        <Link to="#">
                                            Read More <i className="fas fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="bsingle__post mb-50">
                                <div className="bsingle-cs">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Link to="#">Business</Link>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <div className="date-home2">
                                                <i className="fas fa-calendar-alt" /> October 20, 2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bsingle__post-thumb video-p">
                                    <img src={blog01} alt="" />
                                    <Link to="" className="video-i popup-video" onClick={() => setVideo(true)}>
                                        <i className="fas fa-play" />
                                    </Link>
                                </div>

                                {video &&
                                    <>
                                        <div className="mfp-bg mfp-ready"></div>
                                        <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex={-1} style={{ overflow: "hidden auto" }} >
                                            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                                                <div className="mfp-content">
                                                    <div className="mfp-iframe-scaler">
                                                        <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)}>
                                                            ×
                                                        </button>
                                                        <iframe
                                                            className="mfp-iframe"
                                                            src="https://www.youtube.com/embed/6mpDNxI5gFY?autoplay=1"
                                                            frameBorder={0}
                                                            allowFullScreen=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mfp-preloader">Loading...</div>
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className="bsingle__content">
                                    <h2>
                                        <Link to="/blog-details">
                                            This advice emphasizes the importance of maintaining a curious
                                        </Link>
                                    </h2>
                                    <div className="blog__btn">
                                        <Link to="#">
                                            Read More <i className="fas fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="bsingle__post mb-50">
                                <div className="bsingle-cs">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Link to="#">Business</Link>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <div className="date-home2">
                                                <i className="fas fa-calendar-alt" /> October 20, 2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bsingle__post-thumb blog-active">
                                    <Slider {...sliderSettings}>
                                        <div className="slide-post">
                                            <img src={blog02} alt="" />
                                        </div>
                                        <div className="slide-post">
                                            <img src={blog03} alt="" />
                                        </div>
                                        <div className="slide-post">
                                            <img src={blog04} alt="" />
                                        </div>
                                    </Slider>
                                </div>

                                <div className="bsingle__content">
                                    <h2>
                                        <Link to="/blog-details">
                                            Reid Hoffman: Encouraging a culture of experimentation
                                        </Link>
                                    </h2>
                                    <div className="blog__btn">
                                        <Link to="#">
                                            Read More <i className="fas fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="bsingle__post mb-50">
                                <div className="bsingle-cs">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Link to="#">Business</Link>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <div className="date-home2">
                                                <i className="fas fa-calendar-alt" /> October 20, 2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bsingle__post-thumb">
                                    <img src={blog05} alt="" />
                                </div>
                                <div className="bsingle__content">
                                    <h2>
                                        <Link to="/blog-details">
                                            Learning from failure, this advice suggests that it's better
                                        </Link>
                                    </h2>
                                    <div className="blog__btn">
                                        <Link to="#">
                                            Read More <i className="fas fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="bsingle__post mb-50">
                                <div className="bsingle-cs">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Link to="#">Business</Link>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <div className="date-home2">
                                                <i className="fas fa-calendar-alt" /> October 20, 2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bsingle__post-thumb">
                                    <img src={blog06} alt="" />
                                </div>
                                <div className="bsingle__content">
                                    <h2>
                                        <Link to="/blog-details">
                                            To take risks and learn quickly from mistakes rather than being
                                            afraid of failure.
                                        </Link>
                                    </h2>
                                    <div className="blog__btn">
                                        <Link to="#">
                                            Read More <i className="fas fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="pagination-wrap">
                                <nav>
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <Link to="#">
                                                {" "}
                                                <i className="fas fa-long-arrow-left" />
                                            </Link>
                                        </li>
                                        <li className="page-item active">
                                            <Link to="#">1</Link>
                                        </li>
                                        <li className="page-item">
                                            <Link to="#">2</Link>
                                        </li>
                                        <li className="page-item">
                                            <Link to="#">3</Link>
                                        </li>
                                        <li className="page-item">
                                            <Link to="#">...</Link>
                                        </li>
                                        <li className="page-item">
                                            <Link to="#">10</Link>
                                        </li>
                                        <li className="page-item">
                                            <Link to="#">
                                                {" "}
                                                <i className="fas fa-long-arrow-right" />
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="search-3" className="widget widget_search">
                                    <h2 className="widget-title">Search</h2>
                                    <form role="search" method="get" className="search-form" action="http://wordpress.zcube.in/finco/" >
                                        <label>
                                            <span className="screen-reader-text">Search for:</span>
                                            <input type="search" className="search-field" placeholder="Search …" defaultValue="" name="s" />
                                        </label>
                                        <input type="submit" className="search-submit" defaultValue="Search" />
                                    </form>
                                </section>
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <Link to="#">
                                                <i className="fab fa-twitter" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-pinterest-p" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-instagram" />
                                            </Link>
                                            <Link to="#">
                                                <i className="fab fa-wordpress" />
                                            </Link>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Categories</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="#">Branding</Link> (4)
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <Link to="#">Corporat</Link> (3)
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <Link to="#">Design</Link> (3)
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="#">Gallery</Link> (3)
                                        </li>
                                    </ul>
                                </section>
                                <section id="recent-posts-4" className="widget widget_recent_entries">
                                    <h2 className="widget-title">Recent Posts</h2>
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                User Experience Psychology And Performance Smshing
                                            </Link>
                                            <span className="post-date">August 19, 2022</span>
                                        </li>
                                        <li>
                                            <Link to="#">Monthly Web Development Up Cost Of JavaScript</Link>
                                            <span className="post-date">August 19, 2022</span>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                There are many variation passages of like available.
                                            </Link>
                                            <span className="post-date">August 19, 2022</span>
                                        </li>
                                    </ul>
                                </section>
                                <section id="tag_cloud-1" className="widget widget_tag_cloud">
                                    <h2 className="widget-title">Tag</h2>
                                    <div className="tagcloud">
                                        <Link to="#" className="tag-cloud-link tag-link-28 tag-link-position-1" style={{ fontSize: "8pt" }} aria-label="app (1 item)" >app
                                        </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-17 tag-link-position-2" style={{ fontSize: "8pt" }} aria-label="Branding (1 item)" > Branding
                                        </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-20 tag-link-position-3" style={{ fontSize: "8pt" }} aria-label="corporat (1 item)" > corporat
                                        </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-24 tag-link-position-4" style={{ fontSize: "16.4pt" }} aria-label="Design (2 items)" > Design
                                        </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-25 tag-link-position-5" style={{ fontSize: "22pt" }} aria-label="gallery (3 items)" > gallery
                                        </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-26 tag-link-position-6" style={{ fontSize: "8pt" }} aria-label="video (1 item)" > video
                                        </Link>
                                        <Link to="#" className="tag-cloud-link tag-link-29 tag-link-position-7" style={{ fontSize: "16.4pt" }} aria-label="web design (2 items)" > web design
                                        </Link>
                                    </div>
                                </section>
                                <section id="ads" className="widget widget_ads_banner">
                                    <img src={blogbanner} alt="blog-ads-banner" />
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Innerblog