import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../components/Home/Main'
// import Footer from '../components/Footer/Main'
import Hometwo from '../components/Hometwo/Main'
import Homethree from '../components/Homethree/Main'
import About from '../components/About/Main'
import Services from '../components/Services/Main'
import Servicesdetails from '../components/Servicesdetails/Main'
import Blog from '../components/Blog/Main'
import Blogdetails from '../components/Blogdetails/Main'
import Faq from '../components/Faq/Main'
import Team from '../components/Team/Main'
import Teamdetails from '../components/Teamdetails/Main'
import Pricing from '../components/Pricing/Main'
import Shop from '../components/Shop/Main'
import Shopdetails from '../components/Shopdetails/Main'
import Project from '../components/Project/Main'
import Projectdetails from '../components/Projectdetails/Main'
import Contact from '../components/Contact/Main'
import Thankyou from '../components/Thankyou/Main'

function Index() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-two" element={<Hometwo />} />
        <Route path="/home-three" element={<Homethree />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service-details" element={<Servicesdetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<Blogdetails />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/team" element={<Team />} />
        <Route path="/team-details" element={<Teamdetails />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop-details" element={<Shopdetails />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/project-details" element={<Projectdetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/thankyou" element={<Thankyou />} />
      </Routes>
    </>
  )
}

export default Index;
